import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "./products.css";
import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import { HashLink } from "react-router-hash-link";
import shop from "../../Images/SHOP/1.png";
import ProductsDataService from "../../data/products.services";

export default function Products({ sidebar }) {
  const shoppingCart = useSelector((state) => state);
  const [products, setProducts] = useState([]);
  const [activeItem, setActiveItem] = useState(0);
  const [loaded, setLoaded] = useState(false);

  let totalItems = 0;
  for (const item of shoppingCart.cart) {
    totalItems += item.quantity;
  }

  let totalPrice = 0;
  if (shoppingCart.cart.length !== 0) {
    for (const item of shoppingCart.cart) {
      const itemPrice = item.priceChose * item.quantity;
      totalPrice += itemPrice;
    }
  }

  const handleChange = (event, newValue) => {
    setActiveItem(newValue);
    scrollToSection(newValue);
  };

  const maxWidth = window.innerWidth;

  const handleScroll = () => {
    const sections = document.querySelectorAll("section");
    for (let i = sections.length - 1; i >= 0; i--) {
      const rect = sections[i].getBoundingClientRect();

      if (rect.top <= 150) {
        setActiveItem(i);
        break;
      }
    }
  };

  const scrollToSection = (index) => {
    const paddingTop = 115;
    const sections = document.querySelectorAll("section");
    const section = sections[index];
    const offset = section.offsetTop - paddingTop;

    window.scrollTo({ top: offset, behavior: "smooth" });
  };

  const getProducts = async () => {
    try {
      const data = await ProductsDataService.getAllProducts();
      setProducts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      setLoaded(true);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    getProducts();
    if (loaded) {
      const savedPosition = sessionStorage.getItem("scrollPosition");
      if (savedPosition) {
        window.scrollTo(0, parseInt(savedPosition));
        sessionStorage.removeItem("scrollPosition");
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loaded]);

  return (
    <div
      style={
        sidebar
          ? { maxHeight: "100vh", overflow: "hidden" }
          : { minHeight: "100vh" }
      }
    >
      <img
        src={shop}
        className="img-fluid rounded px-1"
        alt="Belma Shop"
        style={{ marginTop: "100px", marginBottom: "50px" }}
      />
      <div className="t mb-5">
        <Box sx={{ maxWidth, bgcolor: "background.paper" }}>
          <Tabs
            value={activeItem}
            onChange={handleChange}
            variant="scrollable"
            allowScrollButtonsMobile
            aria-label="scrollable prevent tabs example"
            sx={{
              minHeight: "60px",
              height: "60px",
              borderTop: "1px solid grey",
              borderBottom: "1px solid grey",
              position: "relative",
              zIndex: 1000,
              "& .MuiTabs-indicator": { display: "none" },
            }}
            className="border d-flex align-items-center px-2"
          >
            {products.map((item, index) => (
              <Tab
                key={item.id}
                label={item.title}
                component={HashLink}
                to={`/belma_shop#${item.title}`}
                scroll={() => scrollToSection(index)}
                sx={{
                  borderRadius: "9999px",
                  minHeight: "35px",
                  height: "35px",
                  minWidth: "150px",
                  textAlign: "center",
                  marginRight: "2px",
                  textDecoration: "none",
                  zIndex: 9999,
                  fontWeight: "500",
                  ...(activeItem === index
                    ? {
                        transition: "all 0.3s",
                        background: "#000",
                        color: "#FFF !important",
                      }
                    : {
                        transition: "all 0.3s",
                        background: "#FFF",
                        color: "#000",
                      }),
                }}
                value={index}
              />
            ))}
          </Tabs>
        </Box>
      </div>

      {products.map((item, indexx) => (
        <section key={item.title} id={item.title}>
          <div>
            <div
              className="col"
              id="menussMargin"
              style={{
                background: "#F8F8F8",
                borderBottom: "1px solid #E0E0E0",
                borderTop: "1px solid #E0E0E0",
              }}
            >
              <h6
                className="px-4 py-4 text-left text-uppercase font-weight-bold"
                style={{ fontWeight: "600", fontFamily: "titre" }}
              >
                {item.title}
              </h6>
            </div>
            {item.products.map((menu, index) =>
              menu.available ? (
                <Link
                  key={index}
                  to={
                    menu.titleMenu
                      ? {
                          pathname: `/belma_shop/${indexx}-${menu.titleMenu.replace(
                            /\s+/g,
                            ""
                          )}`,
                        }
                      : "/"
                  }
                  onClick={() =>
                    sessionStorage.setItem("scrollPosition", window.scrollY)
                  }
                  style={{ textDecoration: "none", outline: "none" }}
                >
                  <div>
                    <div className="row m-0 px-1">
                      <div className="col-8 my-auto overflow-hidden">
                        <p
                          className="text-dark m-0"
                          style={{ fontWeight: "600" }}
                        >
                          {menu.titleMenu}
                        </p>
                        <p
                          className="text-secondary mb-0 text"
                          style={{ fontFamily: "Light" }}
                        >
                          {menu.descriptionMenu}
                        </p>
                        <p className="text-secondary">
                          {Number.parseFloat(menu.priceMenu).toFixed(2)} €
                        </p>
                      </div>
                      <div
                        className="col-4 d-flex align-items-center justify-content-center py-5"
                        style={{ maxHeight: "120px" }}
                      >
                        <img
                          src={menu.url}
                          className="d-block mx-auto img-fluid rounded"
                          alt=""
                          style={{ height: "90px", maxWidth: "100%" }}
                        />
                      </div>
                    </div>
                    <div
                      className="mx-3"
                      style={{ height: "2px", background: "#F8F8F8" }}
                    ></div>
                  </div>
                </Link>
              ) : (
                <div
                  key={index}
                  style={{
                    textDecoration: "none",
                    outline: "none",
                    cursor: "not-allowed",
                  }}
                >
                  <div>
                    <div className="row m-0 px-1">
                      <div className="col-8 my-auto overflow-hidden">
                        <p
                          className="text-dark m-0"
                          style={{
                            fontWeight: "600",
                            textDecoration: "line-through",
                          }}
                        >
                          {menu.titleMenu}
                        </p>
                        <p
                          className="text-secondary mb-0 text"
                          style={{
                            fontFamily: "Light",
                            textDecoration: "line-through",
                          }}
                        >
                          {menu.descriptionMenu}
                        </p>
                        <p
                          className="text-secondary"
                          style={{ textDecoration: "line-through" }}
                        >
                          {Number.parseFloat(menu.priceMenu).toFixed(2)} €
                        </p>
                        <div
                          style={{
                            background: "#F0F0F0",
                            borderRadius: "4px",
                            display: "inline-block",
                            padding: "2px 8px",
                          }}
                        >
                          <span
                            className="text-muted"
                            style={{ fontWeight: "700", fontSize: "12px" }}
                          >
                            Temporairement indisponible
                          </span>
                        </div>
                      </div>
                      <div
                        className="col-4 d-flex align-items-center justify-content-center py-5"
                        style={{ maxHeight: "120px" }}
                      >
                        <img
                          src={menu.url}
                          className="d-block mx-auto img-fluid rounded"
                          alt=""
                          style={{
                            height: "90px",
                            maxWidth: "100%",
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="mx-3"
                      style={{ height: "2px", background: "#F8F8F8" }}
                    ></div>
                  </div>
                </div>
              )
            )}
          </div>
        </section>
      ))}

      <div style={{ height: "90px" }}></div>
      {totalItems > 0 && (
        <div
          style={{ height: "90px" }}
          className="fixed-bottom bg-white border-top py-auto align-items-center justify-content-center"
        >
          <Link
            to="/shopping_cart"
            style={{ textDecoration: "none", color: "#fff" }}
          >
            <div className="d-grid gap-2 m-3 mx-3 ">
              <button
                className="btn btn-primary mt-3 "
                style={{ background: "#c19d56", border: "1px solid #c19d56" }}
              >
                <div className="row justify-content-between">
                  <div
                    className="col-auto px-2 py-1 mx-2 rounded align-self-center"
                    style={{
                      background: "#61481C",
                      border: "1px solid #61481C",
                      fontWeight: "600",
                    }}
                  >
                    {totalItems}
                  </div>
                  <div className="col-auto align-self-center">
                    <p className="m-0 p-0" style={{ fontWeight: "600" }}>
                      Voir panier
                    </p>
                  </div>
                  <div className="col-auto align-self-center">
                    <p className="m-0 p-0" style={{ fontWeight: "600" }}>
                      {totalPrice.toFixed(2)}€
                    </p>
                  </div>
                </div>
              </button>
            </div>
          </Link>
        </div>
      )}
    </div>
  );
}
