import React from "react";
import { useNavigate } from "react-router-dom";

export default function OrderSussecc({ sidebar }) {
  const navigate = useNavigate();

  return (
    <div
      className="container d-flex h-100"
      style={
        sidebar
          ? { maxHeight: "100vh", overflow: "hidden" }
          : { minHeight: "100vh" }
      }
    >
      <div className="row align-self-center">
        <div className="col-12">
          <p className="text-center px-2">
            Nous avons le plaisir de vous annoncer que votre commande est en
            cours de préparation!
          </p>
          <p className="text-center px-2">
            Pour plus d’informations
            sur nos conditions de livraison{" "}
            <span
              onClick={() => navigate("/delivery")}
              className="btn btn-link p-0 m-0"
            >
              cliquez ici
            </span>
          </p>
        </div>
        <div className="px-2">
          <div className="d-grid gap-2">
            <button
              className="btn btn-link"
              type="button"
              onClick={() => navigate("/orders")}
            >
              Voir le détail de ma commande
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
