import React from "react";
import Footer from "../../components/footer/footer";
import GroupeCart from "../../components/groupeCart/groupeCart";
import principal from "../../Images/VIANDE/5.png";

export default function Belma({ sidebar }) {
  return (
    <div
      style={
        sidebar
          ? { maxHeight: "100vh", overflow: "hidden" }
          : { minHeight: "100vh" }
      }
    >
      {/* Partie Scroll */}
      <img
        src={principal} 
        className="img-fluid rounded px-1"
        style={{ marginTop: "100px" }}
      />
      {/* Partir Carts */}
      <GroupeCart />
      {/* Partie footer */}
      <Footer />
    </div>
  );
}
