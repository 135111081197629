import { sendPasswordResetEmail } from "firebase/auth";
import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { auth } from "../../firebase-config";
import "./account.css";

function ModalPopup({ setOpenModal }) {
  const [emailReset, setEmailReset] = useState("");
  const [validation, setValidation] = useState("");

  const sendPsw = async () => {
    try {
      await sendPasswordResetEmail(auth, emailReset);
      setValidation(
        "Le mail vient d'être envoyé, veuillez verifier votre adresse e-mail."
      );
      setTimeout(() => {
        setValidation("");
        setEmailReset("");
        setOpenModal(false);
      }, 3000);
    } catch {
      setValidation("L'adresse e-mail saisie est incorrecte.");
    }
  };
  return (
    <>
      <Modal show={true} fullscreen={true} onHide={() => setOpenModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Mot de passe oublié</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pt-5 col mx-auto" style={{ maxWidth: "500px" }}>
            <p className="text-center p-0 m-0 pb-3">
              Nous vous enverrons un e-mail pour réinitialiser votre mot de
              passe.
            </p>
            <div className="d-grid gap-2 mx-md-5 mt-3 mx-2">
              <Form.Control
                type="email"
                placeholder="Saisir votre e-mail"
                className="px-3 py-1"
                required
                value={emailReset}
                onChange={(e) => setEmailReset(e.target.value)}
              />
            </div>
            <p className="mt-1 px-2">{validation}</p>
            <div className="d-grid gap-2 mx-md-5 mt-3 mx-4">
              <Button
                variant="info btn-block text-white px-3 py-1 bttn"
                type="button"
                onClick={sendPsw}
              >
                Envoyer l'e-mail
              </Button>
              <button
                className="btn btn-outline-danger mx-5 px-3 py-1 bttn"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                Annuler
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalPopup;
