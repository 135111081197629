import React from "react";
import Footer from "../../components/footer/footer";

const styles = {
  content: {
    minHeight: "calc(100vh - 100px)",
    paddingBottom: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    fontFamily: "texte",
  },
  footer: {
    position: "fixed",
    bottom: "0",
    width: "100%",
  },
};

export default function Tracability({ sidebar }) {
  return (
    <div
      style={
        sidebar
          ? { maxHeight: "100vh", overflow: "hidden" }
          : { minHeight: "100vh", overflowX: "hidden" }
      }
    >
      <div style={styles.content}>
        <div className="mt-5 pt-5 px-4 fs-5">
          <p>
            Toutes nos viandes sont halal. Nous travaillons avec AVS et Achahada
            pour vous proposer les meilleurs produits. Toutes les viandes
            proviennent d'éleveurs français privilégiant les normes
            environnementales et le bien-être animal. Nos volailles sont
            certifiées Label Rouge, garantissant des normes de qualité
            supérieure. Nous utilisons des ingrédients naturels sans
            conservateurs dans toutes nos préparations.
          </p>
          <a
            href="/belma_shop"
            className="cta-button"
            style={{
              display: "block",
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            Découvrir nos produits
          </a>
        </div>
      </div>
      <div style={styles.footer}>
        <Footer />
      </div>
    </div>
  );
}
