import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./productShowcase.css";
import { HiOutlinePlusCircle, HiOutlineMinusCircle } from "react-icons/hi";
import { MdOutlineArrowBackIosNew } from "react-icons/md";

export default function ProductShowcase({ productsP, sidebar }) {
  const [nbMugs, setNbMugs] = useState(1);
  const [pay, setPay] = useState(0);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const { id } = useParams();
  const indexProduct = id.substr(0, 1);
  const idProduct = id.substr(2);

  const productClicked = productsP[indexProduct].products.findIndex(
    (obj) => obj.titleMenu?.replace(/\s+/g, "").trim() === idProduct
  );
  const [optionn, setOptionn] = useState(
    productsP[indexProduct].products[productClicked].options[0].name
  );
  const [priceFinal, setPriceFinal] = useState(
    productsP[indexProduct].products[productClicked].options[0].price
  );
  const [priceId, setPriceId] = useState(
    productsP[indexProduct].products[productClicked].options[0].priceId
  );

  const addProduct = () => {
    setNbMugs((nbMugs) => nbMugs + 1);
  };

  const lessProduct = () => {
    if (nbMugs > 1) {
      setNbMugs((nbMugs) => nbMugs - 1);
    }
  };

  const addingInfo = useRef();
  const [display, setDisplay] = useState(true);
  let timerInfo;

  const dispatch = useDispatch();
  const addToCart = (e) => {
    if (pay === 0) {
      setMessage("Obligatoire");
    } else {
      e.preventDefault();
      setMessage("");
      const itemAdded = {
        ...productsP[indexProduct].products[productClicked],
        quantity: nbMugs,
        option: optionn,
        priceChose: priceFinal,
        priceId: priceId,
      };

      dispatch({
        type: "ADDITEM",
        payload: itemAdded,
      });
      navigate("/belma_shop");
      if (addingInfo.current) {
        addingInfo.current.innerText = "Ajouté au panier";
      }
      if (display) {
        setDisplay(false);
        timerInfo = setTimeout(() => {
          if (addingInfo.current) {
            addingInfo.current.innerText = "";
          }
          setDisplay(true);
        }, 500);
      }
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timerInfo);
    };
  }, [timerInfo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={
        sidebar
          ? { maxHeight: "100vh", overflow: "hidden" }
          : { minHeight: "100vh", background: "#F8F8F8" }
      }
    >
      <div>
        <div
          style={{ background: "#FFF" }}
          className="pt-5 col-12 d-flex align-items-center justify-content-center border-top prod"
        >
          <Link
            to="/belma_shop"
            style={{ textDecoration: "none", color: "#fff" }}
            className="backAvt align-middle rounded-circle shadow-lg"
          >
            <MdOutlineArrowBackIosNew color="#c19d56" size={20} />
          </Link>
          <div
            style={{ height: "15rem", width: "100%", overflow: "hidden" }}
            className="d-flex justify-content-center align-items-center"
          >
            <img
              src={productsP[indexProduct].products[productClicked].url}
              alt="shopping"
              className="img-fluid mb-5"
            />
          </div>
        </div>
        <div
          className="p-0"
          style={{ borderTop: "1px solid #E0E0E0", background: "#F8F8F8" }}
        >
          <h5 style={{ fontWeight: "600" }} className="m-0 mt-3 pt-3 px-2">
            {productsP[indexProduct].products[productClicked].titleMenu}
          </h5>
          <p
            style={{ color: "grey", fontSize: "14px", fontFamily: "Light" }}
            className="lead m-0 px-2 pt-2"
          >
            {productsP[indexProduct].products[productClicked].descriptionMenu}
          </p>
          <h6 className="m-0 mt-5 px-2" style={{ fontWeight: "600" }}>
            Choisissez vos options
          </h6>
          {message !== "" && (
            <span className="text-danger px-2">{message}</span>
          )}
          <p
            className="m-0 pb-2 px-2"
            style={{ color: "grey", fontSize: "14px" }}
          >
            Obligatoire
          </p>
          <div>
            <fieldset>
              {productsP[indexProduct].products[productClicked].options.length >
                0 &&
                productsP[indexProduct].products[productClicked].options.map(
                  (item, index) => {
                    return (
                      <div
                        key={index}
                        className="row justify-content-between py-1 mx-3"
                      >
                        <label
                          className="col-auto mr-auto p-0"
                          htmlFor={item.name}
                        >
                          {item.name}
                        </label>
                        <div className="col-auto">
                          <div className="row my-auto justify-content-end">
                            <div className="col-auto m-0">
                              {item.plus && (
                                <p className="m-0 mt-1">
                                  +{" "}
                                  {(
                                    item.price -
                                    productsP[indexProduct].products[
                                      productClicked
                                    ].priceMenu
                                  ).toFixed(2)}{" "}
                                  €
                                </p>
                              )}
                            </div>
                            <div className="col-auto m-0 p-0 ">
                              <input
                                type="radio"
                                id={item.name}
                                value={item.price}
                                onClick={() => {
                                  setPay(item.price);
                                  setOptionn(item.name);
                                  setPriceFinal(item.price);
                                  setPriceId(item.priceId);
                                }}
                                name="options"
                                className="choixX"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
            </fieldset>
          </div>
        </div>
      </div>
      <div style={{ height: "160px" }}></div>
      <div  
        style={{ height: "120px" }}
        className="fixed-bottom bg-white border-top py-auto align-items-center justify-content-center"
      >
        <div className="container overflow-hidden text-center">
          <div className="row gx-1 px-5 mt-1">
            <div className="col">
              <div className="p-2" onClick={() => lessProduct()}>
                <HiOutlineMinusCircle
                  color={nbMugs > 1 ? "#c19d56" : "grey"}
                  size={20}
                />
              </div>
            </div>
            <div className="col">
              <div className="p-2" style={{ fontWeight: "bold" }}>
                {nbMugs}
              </div>
            </div>
            <div className="col">
              <div className="p-2" onClick={() => addProduct()}>
                <HiOutlinePlusCircle color="#c19d56" size={20} />
              </div>
            </div>
          </div>
        </div>
        <div className="d-grid gap-2  mt-3 mx-3">
          {pay === 0 ? (
            <button
              className="btn btn-primary py-2 mx-3"
              style={{
                background: "grey",
                border: "1px solid grey",
                opacity: "0.3",
                transition: "all 0.5s",
                fontWeight: "600",
              }}
              onClick={addToCart}
            >
              Ajouter au panier (
              {productsP[indexProduct].products[productClicked].priceMenu} €)
            </button>
          ) : (
            <button
              className="btn btn-primary py-2 mx-3"
              style={{
                background: "#c19d56",
                border: "1px solid #c19d56",
                transition: "all 0.5s",
                fontWeight: "600",
              }}
              onClick={addToCart}
            >
              Ajouter au panier ({(pay * nbMugs).toFixed(2)} €)
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
