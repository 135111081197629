import React, { useContext, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { UserContext } from "../../data/userContext";
import { useNavigate } from "react-router-dom";
import "../account/account.css";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import UsersDataService from "../../data/users.services";
import { auth } from "../../firebase-config";

const EyeIcon = ({ type, handleToggle }) => (
  <span
    onClick={handleToggle}
    className="position-absolute top-50 end-0 translate-middle-y mx-1"
  >
    {type === "password" ? (
      <AiOutlineEye size={20} />
    ) : (
      <AiOutlineEyeInvisible size={20} />
    )}
  </span>
);

const SignUpForm = ({
  handleForm,
  validation,
  addInputs,
  type,
  handleToggle,
  particulier,
  changeHandlerPart,
}) => (
  <form onSubmit={handleForm}>
    <Form.Group role="form">
      <div className="row justify-content-around mx-3">
        <div className="col-6 form-check mb-3">
          <input
            className="form-check-input choixXX"
            type="radio"
            value="particulier"
            checked={particulier === "particulier"}
            onChange={changeHandlerPart}
          />
          <label className="form-check-label">Particulier</label>
        </div>
        <div className="col-6 form-check">
          <input
            className="form-check-input choixXX"
            type="radio"
            value="professionnel"
            checked={particulier === "professionnel"}
            onChange={changeHandlerPart}
          />
          <label className="form-check-label">Professionnel</label>
        </div>
      </div>
      <Form.Label style={{ fontWeight: "600" }}>Votre email * </Form.Label>
      <Form.Control
        type="email"
        placeholder="Enter email"
        required
        ref={addInputs}
      />

      <Form.Label style={{ fontWeight: "600" }} className="pt-3">
        Votre mot de passe *
      </Form.Label>
      <div className="position-relative">
        <input
          type={type}
          className="form-control"
          placeholder="Password"
          required
          ref={addInputs}
        />
        <EyeIcon type={type} handleToggle={handleToggle} />
      </div>
      <div className="d-grid gap-2 mt-5">
        <Button
          variant="btn-block pt-2 text-light"
          style={{ letterSpacing: "1.2px", background: "#2128F5" }}
          type="submit"
        >
          M'inscrire
        </Button>
      </div>
      <p className="text-danger mt-1">{validation}</p>
    </Form.Group>
  </form>
);

export default function ShopSignUpPage({ sidebar }) {
  const [particulier, setParticulier] = useState("particulier");
  const [type, setType] = useState("password");

  const { signup } = useContext(UserContext);
  const navigate = useNavigate();
  const [validation, setValidation] = useState("");
  const inputs = useRef([]);

  const changeHandlerPart = (changeEvent) => {
    setParticulier(changeEvent.target.value);
  };

  const handleToggle = () => {
    setType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  const addInputs = (el) => {
    if (el && !inputs.current.includes(el)) {
      inputs.current.push(el);
    }
  };

  const navigToSingup = () => {
    navigate("/shop");
  };

  const handleForm = async (e) => {
    e.preventDefault();
    try {
      await signup(inputs.current[0].value, inputs.current[1].value);
      const newUser = {
        firstName: "",
        lastName: "",
        tel: "",
        address: {
          num: "",
          zip: "",
          country: "",
          adrressName: "",
          city: "",
        },
        date_inscription: new Date(),
        type: particulier,
        email: auth.currentUser.email,
      };
      await UsersDataService.addUser(auth.currentUser.uid, newUser);
      setValidation("");
      navigate("/shop");
    } catch {
      setValidation(
        "Un compte existe déjà avec cette adresse email, veuillez vous connecter"
      );
    }
  };

  return (
    <div
      style={
        sidebar
          ? { maxHeight: "100vh", overflow: "hidden" }
          : { minHeight: "100vh" }
      }
    >
      <p
        className="m-0 p-0 mt-5 pt-0 text-center"
        style={{ fontWeight: "600", letterSpacing: "2px", marginTop: "1000px" }}
      >
        INSCRIPTION
      </p>
      <Container className="m-0 p-0">
        <Row className="m-0 p-0">
          <Col
            lg={5}
            md={6}
            sm={12}
            className="p-0 px-3 pt-5 m-auto rounded-lg"
          >
            <SignUpForm
              handleForm={handleForm}
              validation={validation}
              addInputs={addInputs}
              type={type}
              handleToggle={handleToggle}
              particulier={particulier}
              changeHandlerPart={changeHandlerPart}
            />
          </Col>
        </Row>
        <p className="m-0 p-1 text-center mt-2" style={{ fontWeight: "500" }}>
          Vous avez déjà un compte ?{" "}
          <span style={{ color: "#2128F5" }} onClick={navigToSingup}>
            Se connecter
          </span>
        </p>
      </Container>
    </div>
  );
}
