import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import cartIcon from "../../Images/panier (1).svg";
import "./floatingCart.css";

export default function FloatingCart() {
  const { cart } = useSelector((state) => state);

  const totalItems = cart.reduce((total, item) => total + item.quantity, 0);

  return (
    <Link to="/shopping_cart" title="Shopping Cart">
      <div className="floating-cart">
        <div className="img-notif-container">
          <img src={cartIcon} className="icone1" alt="Shopping Cart Icon" />
          {totalItems > 0 && <span className="notif">{totalItems}</span>}
        </div>
      </div>
    </Link>
  );
}
