import React from 'react'
import Footer from '../../components/footer/footer'
import GroupeCart from '../../components/groupeCart/groupeCart'
import SlideShow from '../../components/slide/slideshow';
import image1 from '../../Images/VIANDE/1.jpg';
import image2 from '../../Images/VIANDE/2.png';
import image3 from '../../Images/VIANDE/3.png';

export default function Viandes({sidebar}) {
  const slides = [
    { src: image1, alt: 'Slide 1' },
    { src: image2, alt: 'Slide 2' },
    { src: image3, alt: 'Slide 3' }
];
  return (
    <div style={sidebar ? {maxHeight: '100vh', overflow: "hidden" } : {minHeight: '100vh', overflowX: 'hidden'}}>
        {/* Partie Scroll */}
        <div style={{margin: '100px 5px 0px 5px'}}>
          <SlideShow karim={true} slides={slides}/>
        </div>
        <p className='text-center mt-5 pt-3 px-2 fs-5' style={{fontFamily: 'texte'}}>Des viandes prestige pour les grands gourmets. Une sélection des pièces les plus nobles de Bœuf AOP, que des races rares et des animaux avec une génétique naturelle parfaite. L’affinage des belles pièces se fait dans notre cave de maturation. Le Dry Aged est un affinage 100 % naturel qui exalte les saveurs de la viande pour une expérience gustative incomparable.
        Attention !  Ceux ne sont que des séries limitées.</p>
        {/* Partir Carts */}
        <GroupeCart />
        {/* Partie footer */}
        <Footer />
    </div>
  )
}
