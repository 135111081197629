import React from "react";
import "./navbar.css";
import { FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";
import kiko from "../../Images/LOGO-BELMA_BLEU.png";

export default function Navbar({ openSidebar }) {
  return (
    <div className="tool-bar">
      <div
        className="burger"
        onClick={openSidebar}
        style={{ cursor: "pointer" }}
      >
        <FiMenu />
      </div>
      <div className="gg">
        <Link to="/" style={{ textDecoration: "none", color: "#2128F5" }}>
          <img
            src={kiko}
            alt=""
            className="img-fluid"
            style={{ height: "15px" }}
          />
        </Link>
      </div>
    </div>
  );
}
