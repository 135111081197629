import { collection, getDocs, query, orderBy, where } from "firebase/firestore";
import { db } from "../firebase-config";

const ordersCollectionRef = collection(db, "orders");

class OrdersDataService {
  getAllOrders = (id) => {
    const q = query(
      ordersCollectionRef,
      where("email_order", "==", id),
      orderBy("date_order", "desc")
    );
    return getDocs(q);
  };
}

export default new OrdersDataService();
