import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { auth } from "../../firebase-config";
import ShopAccount from "./shopAccount";
import UsersDataService from "../../data/users.services";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import PaymentForm from "../../components/paymentForm";
import { RiErrorWarningLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { PAYPAL_CLIENT_ID, ACOMPTE } from '../../utils/constants'
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { CURRENCY_CODE } from "../../utils/constants"
import { Button } from "react-bootstrap";


const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
const mapApiJs = "https://maps.googleapis.com/maps/api/js";


// load google map api js


function loadAsyncScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
    });
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  });
}


export default function Shop({ sidebar, storageState, currentU }) {
  const searchInput = useRef([]);
  const [address, setAddress] = useState({});
  const dispatch = useDispatch();
  const storeState = useSelector((state) => state);

  // Afficher les inputs
  const [showInputLastName, setShowInputLastName] = useState(false);
  const [showInputFirstName, setShowInputFirstName] = useState(false);
  const [showInputPhone, setShowInputPhone] = useState(false);
  const [showInputAddress, setShowInputAddress] = useState(false);
  //
  const [user, setUser] = useState({});
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [tel, setTel] = useState("");
  const [priceDelivery, setPriceDelivery] = useState(0);
  const [loadingUser, setloadingUser] = useState(false);

  const [priceDeliveryId, setPriceDeliveryId] = useState(
    "price_1NADtTGLmLhpYUov9kkeFarx"
  );
  const [totalPrice, setTotalPrice] = useState(0);
  const bottomRef = useRef(null);
  const [valid, setValid] = useState(false);
  const [success, setSuccess] = useState(false);

  const truncateFloat = (amount) => {
    amount = parseFloat(amount)
    return Math.floor(amount * 100) / 100;
  }

  const validInfo = () => {
    let userDetails = {}
    if (typeof (storeState.userDetails) === 'string') {
      userDetails = JSON.parse(storeState.userDetails)
    } else {
      userDetails = storeState.userDetails
    }

    if (user.type === "professionnel") {
      if (firstName === "" || address === "" || tel === "") {
        setValid(true)
      } else {
        userDetails.address = address
        userDetails.firstName = firstName
        userDetails.tel = tel
        dispatch({
          type: "USERDETAILS",
          payload: userDetails,
        });
        setSuccess(true)
      }
    } else {
      if (lastName === "" || firstName === "" || address === "" || tel === "") {
        setValid(true)
      } else {
        userDetails.address = address
        userDetails.firstName = firstName
        userDetails.lastName = lastName
        userDetails.tel = tel
        dispatch({
          type: "USERDETAILS",
          payload: userDetails,
        });
        setSuccess(true)
      }
    }
    bottomRef.current.scrollIntoView({ behavior: 'smooth' });

  }
  const extractAddress = (place) => {
    const address = {
      city: "",
      zip: "",
      country: "",
      adrressName: "",
      num: "",
      country_Code: "",
      area_Code: "",
    };


    if (!Array.isArray(place?.address_components)) {
      return address;
    }

    place.address_components.forEach((component) => {
      const types = component.types;
      const value = component.long_name;
      const short_value = component.short_name;

      if (types.includes("locality")) {
        address.city = value;
      }

      if (types.includes("postal_code")) {
        address.zip = value;
      }

      if (types.includes("country")) {
        address.country = value;
        address.country_Code = short_value;
      }

      if (types.includes("administrative_area_level_1")) {
        address.area_Code = short_value;
      }

      if (types.includes("street_number")) {
        address.num = value;
      }
      if (types.includes("route")) {
        address.adrressName = value;
      }

    });

    let user = {}
    if (storeState.user) {
      if (typeof (storeState.user) === 'string') {
        user = JSON.parse(storeState.user)
      } else {
        user = storeState.user
      }
      user.address = address
      dispatch({
        type: "CURRENTUSER",
        payload: JSON.stringify(user)
      });
    }
    return address;
  };

  const getValid = (id) => {
    setValid(id);
  };

  const addInputs = (el) => {
    searchInput.current[0] = el;
  };

  // init autocomplete
  window.initAutocomplete = () => {
    if (!searchInput.current[0]) return;

    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current[0]
    );
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );
  };

  // init gmap script
  const initMapScript = () => {
    // if script already loaded
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly&callback=window.initAutocomplete`;
    return loadAsyncScript(src, window.initAutocomplete);
  };

  // do something on address change
  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    setAddress(extractAddress(place));
    setSuccess(false)
  };

  //Fonction pour modifier l'adresse'
  const handleAdresse = () => {
    setShowInputAddress((showInputAddress) => true);
    initMapScript().then(() => {
      window.initAutocomplete();
    });

  };

  const changeFirstName = (e) => {
    setFirstName(e.target.value)
    setSuccess(false)
  };

  const changeLastName = (e) => {
    setLastName(e.target.value)
    setSuccess(false)
  };

  const changeTel = (e) => {
    setTel(e)
    setSuccess(false)
  };

  const getUser = async () => {
    try {
      const docSnap = await Promise.race([
        UsersDataService.getUser(auth.currentUser.uid),

        new Promise((resolve, reject) => {
          setTimeout(() => {
            reject(new Error('Timeout')); // Déclencher une erreur après 5 secondes
          }, 5000); // 5000 millisecondes = 5 secondes
        })
      ]);
      setUser(docSnap.data());
      setFirstName((firstName) => docSnap.data().firstName);
      setLastName((lastName) => docSnap.data().lastName);
      setTel((lastName) => docSnap.data().tel);
      setAddress((address) => docSnap.data().address);


      if (docSnap.data().lastName === "") {
        setShowInputLastName((showInputLastName) => false);
      } else {
        setShowInputLastName((showInputLastName) => true);
      }
      if (docSnap.data().firstName === "") {
        setShowInputFirstName((showInputFirstName) => false);
      } else {
        setShowInputFirstName((showInputFirstName) => true);
      }
      if (docSnap.data().tel === "") {
        setShowInputPhone((showInputPhone) => false);
      } else {
        setShowInputPhone((showInputPhone) => true);
      }
      setShowInputAddress((showInputAddress) => false);

      let userDetails = {}

      userDetails.address = docSnap.data().address
      userDetails.lastName = docSnap.data().lastName
      userDetails.firstName = docSnap.data().firstName
      userDetails.tel = docSnap.data().tel
      userDetails.type = docSnap.data().type
      userDetails.mail = docSnap.data().email


      dispatch({
        type: "USERDETAILS",
        payload: userDetails,
      });
      docSnap.data() ? setloadingUser(true) : setloadingUser(false)

    } catch (error) {
      // En cas d'erreur ou de dépassement du délai
      console.error(error);
      window.location.reload();
    }
  };
  useEffect(() => {
    let listeItem = [];
    let listePriceIdSelected = [];
    for (const item of storeState.cart) {
      const itemPrice = {
        qty: item.quantity,
        name: item.titleMenu,
        priceItem: item.priceChose,
        option: item.option,
      };
      const itemPriceId = { price: item.priceId, quantity: item.quantity, amount: item.priceChose };
      listeItem.push(itemPrice);
      listePriceIdSelected.push(itemPriceId);
    }

    dispatch({
      type: "LISTITEM",
      payload: listeItem
    });

  }, [])

  useEffect(() => {
    initMapScript().then(() => {
      window.initAutocomplete();
    });


    if (storageState.cart.length !== 0) {
      let finaltotalPrice = 0
      let itemPrice = 0
      let acomptePrice = 0
      for (const item of storageState.cart) {
        if (item.titleMenu === ACOMPTE) {
          itemPrice = item.priceChose * item.quantity;
          acomptePrice += itemPrice;
        } else {
          itemPrice = item.priceChose * item.quantity;
          finaltotalPrice += itemPrice;
        }
      }

      let PriceDelivery = 0
      if (finaltotalPrice === 0 || finaltotalPrice >= 140) {
        PriceDelivery = 0
      } else if (finaltotalPrice > 0 && finaltotalPrice < 50) {
        PriceDelivery = 25
      } else if (finaltotalPrice >= 50 && finaltotalPrice < 140) {
        PriceDelivery = 20
      }

      setTotalPrice(finaltotalPrice + acomptePrice)

      dispatch({
        type: "AMOUNT",
        payload: truncateFloat(finaltotalPrice)
      });
      dispatch({
        type: "PRICEDELIVERY",
        payload: truncateFloat(PriceDelivery)
      });
      dispatch({
        type: "AMOUNTACOMPTE",
        payload: truncateFloat(acomptePrice)
      });
    }
    if (currentU) {
      getUser();
    }
  }, []);


  useEffect(() => {
    if (currentU) {
      getUser();
    }

  }, [currentU]);
  return (
    <div
      className="mt-5"
      style={
        sidebar
          ? { maxHeight: "100vh", overflow: "hidden" }
          : { background: "#F8F8F8", minHeight: "100vh" }
      }
    >
      {currentU ? (
        <div>
          <PayPalScriptProvider options={{ "client-id": PAYPAL_CLIENT_ID.clientId, currency: CURRENCY_CODE }}>
            <p className="pt-5 text-center" style={{ fontWeight: "600" }}>
              Confirmation de votre commande
            </p>
            <div className="bg-white mt-5 border-top pt-2 pb-3">
              {/* Partie Address */}
              {address === {} || showInputAddress ? (
                <div className="col p-0 m-0 px-2 mx-2">
                  <div className="col-12">
                    <p
                      className="m-0 p-0 px-1 text-secondary"
                      style={{ fontWeight: "500", fontSize: "0.8rem" }}
                    >
                      Adresse
                    </p>
                    <div className="col-12">
                      <input
                        ref={addInputs}
                        type="text"
                        className="form-control"
                        style={{
                          border: "none",
                          borderBottom: "1px solid #C0C0C0",
                        }}
                        aria-describedby="emailHelp"
                        placeholder="Saisir votre adresse..."
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col p-0 m-0 px-2 mx-2">
                  <div className="col-12">
                    <p
                      className="m-0 p-0 px-1 text-secondary"
                      style={{ fontWeight: "500", fontSize: "0.8rem" }}
                    >
                      Adresse{" "}
                    </p>
                    <div className="col-12">
                      <input
                        onClick={handleAdresse}
                        value={`${address.num ? `${address.num}, ` : ""}${address.adrressName ? `${address.adrressName}, ` : ""
                          }${address.zip ? `${address.zip}, ` : ""}${address.city ? `${address.city}, ` : ""
                          }${address.country}`}
                        type="text"
                        onChange={(e) => console.log(e)}
                        className="form-control"
                        style={{
                          border: "none",
                          borderBottom: "1px solid #C0C0C0",
                        }}
                        aria-describedby="emailHelp"
                        placeholder="Saisir votre adresse..."
                      />
                    </div>
                  </div>
                </div>
              )}
              {user.type === "particulier" ? (
                <div>
                  {/* Partie LastName */}
                  <div className="col p-0 m-0 px-2 mx-2">
                    <div className="col-12">
                      <p
                        className="m-0 p-0 px-1 text-secondary"
                        style={{ fontWeight: "500", fontSize: "0.8rem" }}
                      >
                        Nom{" "}
                      </p>
                      {user.lastName === "" && !showInputLastName ? (
                        <div className="row">
                          <div
                            className="col-8"
                            onClick={() =>
                              setShowInputLastName((showInputLastName) => true)
                            }
                          >
                            <p
                              className="text-truncate m-0 p-0 pt-1 px-1 text-danger"
                              style={{ fontSize: "0.61rem" }}
                            >
                              Veuillez ajouter votre nom
                            </p>
                          </div>
                          {/* <div className='col-4'>
                            <Button variant="info btn-block py-1 bttn text-danger" onClick={() => setShowInputLastName(showInputLastName => true)} type="button" style={{background: 'transparent', border: 'none', fontSize: '0.7rem'}}>Ajouter</Button>
                        </div> */}
                        </div>
                      ) : (
                        <div className="col-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              style={{
                                border: "none",
                                borderBottom: "1px solid #C0C0C0",
                              }}
                              aria-describedby="emailHelp"
                              placeholder="Nom"
                              value={lastName}
                              onChange={(e) => changeLastName(e)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col p-0 m-0 px-2 mx-2 pt-2">
                    <div className="col-12">
                      <p
                        className="m-0 p-0 px-1 text-secondary"
                        style={{ fontWeight: "500", fontSize: "0.8rem" }}
                      >
                        Prénom{" "}
                      </p>
                      {user.firstName === "" && !showInputFirstName ? (
                        <div className="row">
                          <div
                            className="col-8"
                            onClick={() =>
                              setShowInputFirstName(
                                (showInputFirstName) => true
                              )
                            }
                          >
                            <p
                              className="text-truncate m-0 p-0 pt-1 px-1 text-danger"
                              style={{ fontSize: "0.61rem" }}
                            >
                              Veuillez ajouter votre prénom
                            </p>
                          </div>
                          {/* <div className='col-4'>
                            <Button variant="info btn-block py-1 bttn text-danger" onClick={() => setShowInputFirstName(showInputFirstName => true)} type="button" style={{background: 'transparent', border: 'none', fontSize: '0.7rem'}}>Ajouter</Button>
                        </div> */}
                        </div>
                      ) : (
                        <div className="col-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              style={{
                                border: "none",
                                borderBottom: "1px solid #C0C0C0",
                              }}
                              aria-describedby="emailHelp"
                              placeholder="Prénom"
                              value={firstName}
                              onChange={(e) => changeFirstName(e)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {/* Partie Raison social */}
                  <div className="col p-0 m-0 px-2 mx-2 pt-2">
                    <div className="col-12">
                      <p
                        className="m-0 p-0 px-1 text-secondary"
                        style={{ fontWeight: "500", fontSize: "0.8rem" }}
                      >
                        Raison social{" "}
                      </p>
                      {user.firstName === "" && !showInputFirstName ? (
                        <div className="row">
                          <div
                            className="col-8"
                            onClick={() =>
                              setShowInputFirstName(
                                (showInputFirstName) => true
                              )
                            }
                          >
                            <p
                              className="text-truncate m-0 p-0 pt-1 px-1 text-danger"
                              style={{ fontSize: "0.61rem" }}
                            >
                              Veuillez ajouter votre prénom
                            </p>
                          </div>
                          {/* <div className='col-4'>
                            <Button variant="info btn-block py-1 bttn text-danger" onClick={() => setShowInputFirstName(showInputFirstName => true)} type="button" style={{background: 'transparent', border: 'none', fontSize: '0.7rem'}}>Ajouter</Button>
                        </div> */}
                        </div>
                      ) : (
                        <div className="col-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              style={{
                                border: "none",
                                borderBottom: "1px solid #C0C0C0",
                              }}
                              aria-describedby="emailHelp"
                              placeholder="Raison social"
                              value={firstName}
                              onChange={(e) => changeFirstName(e)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {/* Partie Phone */}
              <div className="col p-0 m-0 px-2 mx-2 pt-2">
                <div className="col-12">
                  <p
                    className="m-0 p-0 px-1 text-secondary"
                    style={{ fontWeight: "500", fontSize: "0.8rem" }}
                  >
                    Numéro de téléphone
                  </p>
                  {user.tel === "" && !showInputPhone ? (
                    <div className="row">
                      <div
                        className="col-12"
                        onClick={() =>
                          setShowInputPhone((showInputPhone) => true)
                        }
                      >
                        <p
                          className="text-truncate m-0 p-0 pt-1 px-1 text-danger"
                          style={{ fontSize: "0.61rem" }}
                        >
                          Veuillez ajouter un numéro de téléphone
                        </p>
                      </div>
                      {/* <div className='col-4'>
                            <Button variant="info btn-block py-1 bttn text-danger" onClick={() => setShowInputPhone(showInputPhone => true)} type="button" style={{background: 'transparent', border: 'none', fontSize: '0.7rem'}}>Ajouter</Button>
                        </div> */}
                    </div>
                  ) : (
                    <div className="col-12 pt-2">
                      <PhoneInput
                        placeholder="Saisir votre numéro"
                        inputStyle={{ width: "100%" }}
                        country="fr"
                        value={tel}
                        onChange={(e) => changeTel(e)}
                      />
                    </div>
                  )}
                </div>
              </div>


              <div className="col p-0 m-0 px-2 mx-2 pt-2">
                {valid ? (
                  address.country === "" ? (
                    <div className="row">
                      <div className="text-danger col-2 text-end">
                        <RiErrorWarningLine />
                      </div>
                      <div
                        className="col text-start text-truncate text-danger pt-1 px-0"
                        style={{ fontSize: "0.8rem" }}
                      >
                        Veuillez saisir votre adresse
                      </div>
                    </div>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}

                {valid ? (
                  lastName === "" && user.type === "particulier" ? (
                    <div className="row">
                      <div className="text-danger col-2 text-end">
                        <RiErrorWarningLine />
                      </div>
                      <div
                        className="col text-start text-truncate text-danger pt-1 px-0"
                        style={{ fontSize: "0.8rem" }}
                      >
                        Veuillez saisir votre nom
                      </div>
                    </div>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}

                {valid ? (
                  firstName === "" ? (
                    <div className="row">
                      <div className="text-danger col-2 text-end">
                        <RiErrorWarningLine />
                      </div>
                      <div
                        className="col text-start text-truncate text-danger pt-1 px-0"
                        style={{ fontSize: "0.8rem" }}
                      >
                        Veuillez saisir votre prénom
                      </div>
                    </div>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}

                {valid ? (
                  tel === "" ? (
                    <div className="row">
                      <div className="text-danger col-2 text-end">
                        <RiErrorWarningLine />
                      </div>
                      <div
                        className="col text-start text-truncate text-danger pt-1 px-0"
                        style={{ fontSize: "0.8rem" }}
                      >
                        Veuillez saisir votre numéro de téléphone
                      </div>
                    </div>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
              </div>
              <div className='div-valid'>
                {success ? <span className="info-billing">Informations validé!</span> : loadingUser ? <Button
                  variant="valid btn-block text-white px-3 py-1 "
                  type="button"
                  onClick={() => validInfo()}
                >
                  Je valide mes informations
                </Button> : <div className="spinner"></div>}

              </div>
              <div
                style={{ height: "1px", width: "95%" }}
                className="border-top mx-auto mt-3 mb-2"
              ></div>
              {/* <div className="d-flex justify-content-between">
            <p className="px-2 m-0">Sous total</p>
            <p className="px-2 m-0">{totalPrice.toFixed(2)} €</p>
          </div>
          <div className="d-flex justify-content-between">
            <p className="px-2 m-0">Frais de livraison</p>
            <p className="px-2 m-0">{Number.parseFloat(priceDelivery).toFixed(2)} €</p>
          </div> */}
            </div>

            <div style={{ height: "160px", background: "#F8F8F8" }}></div>
            <div
              style={{ height: "120px" }}
            //className="fixed-bottom bg-white border-top py-auto align-items-center justify-content-center"
            >


              <div className="d-grid gap-2 m-3 mx-3 ">
                {/* <div className="d-flex justify-content-between pb-0">
              <p className="text-black">Total de commande</p>
              <p className="text-black" style={{fontWeight: '600'}}>{((Number.parseFloat(totalPrice))+(Number.parseFloat(priceDelivery))).toFixed(2)} €</p>
            </div> */}

                <Link
                  to="/shop"
                  style={{ textDecoration: "none", color: "#fff" }}
                  className="p-0 m-0"
                >
                  <div className="d-grid gap-2" ref={bottomRef}>
                    <PaymentForm
                      somme={
                        truncateFloat(totalPrice) +
                        truncateFloat(priceDelivery)
                      }
                      priceDeliveryId={priceDeliveryId}
                      priceDelivery={priceDelivery}
                      infoLastName={lastName}
                      infoFirstName={firstName}
                      infoTel={tel}
                      infoAddress={address}
                      infoUser={user}
                      fct={success}
                    />
                  </div>
                </Link>
              </div>
            </div>
          </PayPalScriptProvider>
        </div>
      ) : (
        <ShopAccount sidebar={sidebar} />
      )}
    </div>
  );
}
