export const data = [
  {
    question: "Quelle est la provenance de votre viande ?",
    answer:
      "Tous nos viandes proviennent d´éleveurs français, respectueux de l’environnement et du bien-être animal.",
  },
  {
    question:
      "Vous utilisez des produits chimiques ou des conservateurs dans vos préparations ?",
    answer:
      "Non, toutes nos préparations sont faites avec des produits naturels et sans conservateurs.",
  },
  {
    question: "Vous êtes halal ?",
    answer: "Oui tous nos produits sont halal.",
  },
  {
    question: "Vous êtes certifiés AVS ou Achahada ?",
    answer:
      "Tous nos produits sont halal et nous travaillons avec les deux certifications, AVS et Achahada afin de sélectionner les meilleurs produits des deux. Si vous avez d’avantages de questions vous pouvez nous demander lors de votre venue les certifications des produits.",
  },
  {
    question: "Votre volaille est sans électronarcose ?",
    answer:
      "Notre volaille est fermière label rouge et elle est abattue sans électronarcose",
  },
  {
    question:
      "Est-ce-que je peux commander mes produits par téléphone pour gagner du temps avant de venir en boutique ?",
    answer:
      "Oui, n´hésitez pas à nous appeler, nous sommes à votre écoute et pouvons préparer votre commande en avance avec plaisir.",
  },
  {
    question: "Je peux me garer facilement quand je vais à la boutique ?",
    answer:
      "Vous avez quelques places devant la boutique, mais si elles sont déjà occupées vous avez un grand parking a une centaine de mètres.",
  },
  {
    question:
      "J´ai un souci avec ma commande où avec les produits comment je peux faire ?",
    answer:
      "Votre satisfaction est notre priorité avant tout. N´hésitez pas à nous appeler à la boutique 01411683338 pendant nos horaires d´ouverture, et nous trouverons une solution",
  },
  {
    question: "Quels sont vos horaires d´ouverture ?",
    answer:
      "Nous sommes ouverts mardi, mercredi, jeudi, samedi, dimanche de 9h30 à 20h Vendredi de 9h30 a 12h30 et de 14h30 à 20h Nous sommes fermés les lundis.",
  },
];
