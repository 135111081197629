import React, { useState } from "react";
import "./faq.css";
import { data } from "../../data/faq/faqData";

export default function Faq() {
  const [selected, setSelected] = useState(null);
  const toggle = (i) => {
    if (selected ===  i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  return (
    <div className="wrapperrr">
      <div className="accordionnn">
        {data.map((item, index) => (
          <div className="itemmm mx-3" key={index}>
            <div className="titleee" onClick={() => toggle(index)}>
              <h6>{item.question}</h6>
              <span>{selected === index ? "-" : "+"}</span>
            </div>
            <div
              className={selected === index ? "contenttt showww" : "contenttt"}
            >
              {item.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
