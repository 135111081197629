import React from "react";
import { Link } from "react-router-dom";
import "./navbar.css";

export default function Sidebar({ sidebar, closeSidebar }) {
  return (
    <div className={sidebar ? "sidebar sidebar--open" : "sidebar"}>
      <Link to="/star" className="linkLi" onClick={closeSidebar}>
        NOTRE STAR BUTCHER
      </Link>
      <Link to="/prestige_meats" className="linkLi" onClick={closeSidebar}>
        NOS VIANDES PRESTIGE
      </Link>
      <Link to="/belma_shop" className="linkLi" onClick={closeSidebar}>
        BELMA SHOP
      </Link>
      <Link to="/belma_story" className="linkLi" onClick={closeSidebar}>
        LA BOUTIQUE
      </Link>
      <Link to="/delivery" className="linkLi" onClick={closeSidebar}>
        LA LIVRAISON
      </Link>
      <Link to="/halal_tracability" className="linkLi" onClick={closeSidebar}>
        HALAL ET TRAÇABILITÉ
      </Link>
    </div>
  );
}
