import { collection, getDocs, query, orderBy, where } from "firebase/firestore";
import { db } from "../firebase-config";

const codepromoCollectionRef = collection(db, "codepromo");

class CodePromoDataService {
  getAllCodes = (title) => {
    const q = query(
      codepromoCollectionRef
    );
    return getDocs(q);
  };
}

export default new CodePromoDataService();
