import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase-config";
import { Button, Alert, Modal } from "react-bootstrap";
import UsersDataService from "../../data/users.services";
import OrdersDataService from "../../data/orders.services";
import "./profil.css";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useContext } from "react";
import { UserContext } from "../../data/userContext";

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
const mapApiJs = "https://maps.googleapis.com/maps/api/js";
// const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';

// load google map api js

function loadAsyncScript(src, callback) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
      onload: () => {
        resolve(script);
        if (callback) callback();
      },
    });
    document.head.appendChild(script);
  });
}

const extractAddress = (place) => {
  const address = {
    city: "",
    zip: "",
    country: "",
    adrressName: "",
    num: "",
    country_Code: "",
    area_Code: "",
    billing_address: ""
  };


  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach((component) => {
    const types = component.types;
    const value = component.long_name;
    const short_value = component.short_name;

    if (types.includes("locality")) {
      address.city = value;
    }

    if (types.includes("postal_code")) {
      address.zip = value;
    }

    if (types.includes("country")) {
      address.country = value;
      address.country_Code = short_value;
    }

    if (types.includes("administrative_area_level_1")) {
      address.area_Code = short_value;
    }

    if (types.includes("street_number")) {
      address.num = value;
    }
    if (types.includes("route")) {
      address.adrressName = value;
    }
    address.billing_address = address.num + ' ' + address.adrressName;
   });
  return address;   
};
export default function Profil({ sidebar, type }) {
  const { currentUser } = useContext(UserContext);
  const [orders, setOrders] = useState([]);
  const dispatch = useDispatch();   

  const getCommandes = async () => {
    const data = await OrdersDataService.getAllOrders(currentUser.email);
    setOrders(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("fr-FR", options);
  };
  const searchInput = useRef([]);
  const navigate = useNavigate();
  const [address, setAddress] = useState({});
  // Afficher les inputs
  const [showInputLastName, setShowInputLastName] = useState(false);
  const [showInputFirstName, setShowInputFirstName] = useState(false);
  const [showInputPhone, setShowInputPhone] = useState(false);
  const [showInputAddress, setShowInputAddress] = useState(false);
  //
  const [user, setUser] = useState({});
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [tel, setTel] = useState("");
  const [show, setShow] = useState(false);
  const [showInfo, setShowInfo] = useState(true);

  const addInputs = (el) => {
    searchInput.current[0] = el;
  };

  // init autocomplete
  window.initAutocomplete = () => {
    if (!searchInput.current[0]) return;

    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current[0]
    );
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );
  };

  // init gmap script
  const initMapScript = () => {
    // if script already loaded
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly&callback=window.initAutocomplete`;
    return loadAsyncScript(src);
  };

  // do something on address change
  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    setAddress(extractAddress(place));
  };

  //Fonction qui initialise les donnée lors de navigation
  const handleNavigateInitialisation = () => {
    setShowInfo(false);
    getUser();
  };

  //Fonction pour modifier les informations perso
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      if (auth.currentUser.uid !== undefined && auth.currentUser.uid !== "") {
        if (showInputFirstName || showInputLastName || showInputPhone) {
          await UsersDataService.updateUser(
            lastName,
            firstName,
            auth.currentUser.uid
          );
          await UsersDataService.updateUserTel(tel, auth.currentUser.uid);
          const addressCurrent = {
            city: address.city ? address.city : "",
            zip: address.zip ? address.zip : "",
            country: address.country ? address.country : "",
            adrressName: address.adrressName ? address.adrressName : "",
            num: address.num ? address.num : "",
            country_Code: address.country_Code ? address.country_Code : "",
            area_Code: address.area_Code ? address.area_Code : "",
            billing_address: address.billing_address ? address.billing_address : "",


          };
          await UsersDataService.updateAdress(
            addressCurrent,
            auth.currentUser.uid
          );
          setShow((show) => true);
          setTimeout(() => {
            setShow((show) => false);
          }, "3000");
        }
      }
    } catch (err) {}
  };

  //Fonction pour modifier l'adresse'
  const handleAdresse = () => {
    setShowInputAddress((showInputAddress) => true);
    initMapScript().then(() => {
      window.initAutocomplete();
    });
  };

  const getUser = async () => {
    const docSnap = await UsersDataService.getUser(auth.currentUser.uid);
    setUser(docSnap.data());
    setFirstName((firstName) => docSnap.data().firstName);
    setLastName((lastName) => docSnap.data().lastName);
    setTel((lastName) => docSnap.data().tel);
    setAddress((address) => docSnap.data().address);
    if (docSnap.data().lastName === "") {
      setShowInputLastName((showInputLastName) => false);
    } else {
      setShowInputLastName((showInputLastName) => true);
    }
    if (docSnap.data().firstName === "") {
      setShowInputFirstName((showInputFirstName) => false);
    } else {
      setShowInputFirstName((showInputFirstName) => true);
    }
    if (docSnap.data().tel === "") {
      setShowInputPhone((showInputPhone) => false);
    } else {
      setShowInputPhone((showInputPhone) => true);
    }
    setShowInputAddress((showInputAddress) => false);
  };
  const logOut = async () => {
    try {
      await signOut(auth);
      dispatch({
        type: "DELETEITEM",
      });
      dispatch({
        type: "DELETEPROMO",
      });
      dispatch({
        type: "DELETEUSERDETAILS",
      });
      dispatch({
        type: "DELETEAMOUNT",
      });
      dispatch({
        type: "DELETELISTITEM",
      });
      navigate("/");
    } catch {
      alert(
        "Un problème est survenu Veuillez verifier votre connexion internet et recommencer."
      );
    }
  };

  useEffect(() => {
    setShowInfo(type);
    initMapScript().then(() => {
      window.initAutocomplete();
    });
    if (auth.currentUser.uid !== undefined && auth.currentUser.uid !== "") {
      getUser();
      getCommandes();
    }
  }, []);

  const [showOrderDetail, setShowOrderDetail] = useState(false);
  const [order, setOrder] = useState({});
  const [priceDelivery, setPriceDelivery] = useState(0);
  const getPriceDelivery = async (totalPrice) => {
    if (totalPrice === 0 || totalPrice >= 140) {
      setPriceDelivery((priceDelivery) => 0);
    }
    if (totalPrice > 0 && totalPrice < 50) {
      setPriceDelivery((priceDelivery) => 25);
    }
    if (totalPrice >= 50 && totalPrice < 140) {
      setPriceDelivery((priceDelivery) => 20);
    }
  };

  return (
    <div
      className="pt-5"
      style={
        sidebar
          ? { maxHeight: "100vh", overflow: "hidden" }
          : { minHeight: "100vh" }
      }
    >
      <Modal
        show={showOrderDetail}
        onHide={() => setShowOrderDetail(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Votre commande {order.num_order}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="bg-white pt-2 pb-3">
              {order?.items?.map((item, index) => (
                <div className="row align-items-center mx-0" key={index}>
                  <div className="col mr-auto px-2 py-1">
                    <span style={{ fontWeight: "600", color: "#1c2d59" }}>
                      {item.qty}x
                    </span>
                    <span style={{ fontWeight: "600", paddingLeft: "10px" }}>
                      {item.name}
                    </span>
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "0.8rem",
                        fontStyle: "italic",
                      }}
                      className="text-secondary"
                    >
                      {" "}
                      - {item.option}
                    </span>
                  </div>
                  <div className="col-auto px-2 py-1">
                    {item.priceItem.toFixed(2)} €
                  </div>
                </div>
              ))}
              <div
                style={{ height: "1px", width: "95%" }}
                className="border-top mx-auto mt-3 mb-2"
              ></div>
              <div className="d-flex justify-content-between">
                <p className="px-2 m-0">Sous total</p>
                <p className="mb-0">
                  {Number.parseFloat(order.montant - priceDelivery).toFixed(2)}{" "}
                  €
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="px-2 m-0">Frais de livraison</p>
                <p className="mb-2">{priceDelivery.toFixed(2)} €</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="px-2 m-0">Total de commande</p>
                <p className="text-black" style={{ fontWeight: "600" }}>
                  {Number.parseFloat(order.montant).toFixed(2)} €
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-secondary"
            onClick={() => setShowOrderDetail(false)}
          >
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
      {show ? (
        <Alert
          variant="success"
          onClose={() => setShow(false)}
          dismissible
          className="mt-4 mx-2"
        >
          <p className="p-0 m-0">Vos informations ont bien été ajoutées</p>
        </Alert>
      ) : (
        <></>
      )}

      <div className="my-5 mx-0">
        <div className="row align-items-center justify-content-center mx-auto">
          <div className="col-6">
            <div className="d-grid gap-2">
              <button
                className="btn btn-primary p-1 px-3 py-2"
                type="button"
                style={
                  showInfo
                    ? {
                        background: "black",
                        color: "white",
                        border: "1px solid black",
                        borderRadius: "99px",
                        fontSize: "0.8rem",
                        fontWeight: "600",
                      }
                    : {
                        background: "transparent",
                        color: "black",
                        border: "none",
                        borderRadius: "99px",
                        fontSize: "0.8rem",
                        fontWeight: "600",
                      }
                }
                onClick={() => setShowInfo((showInfo) => true)}
              >
                Mes informations
              </button>
            </div>
          </div>
          <div className="col-6">
            <div className="d-grid gap-2">
              <button
                className="btn btn-primary p-1 py-2"
                type="button"
                style={
                  showInfo
                    ? {
                        background: "transparent",
                        color: "black",
                        border: "none",
                        borderRadius: "99px",
                        fontSize: "0.8rem",
                        fontWeight: "600",
                      }
                    : {
                        background: "black",
                        color: "white",
                        border: "1px solid black",
                        borderRadius: "99px",
                        fontSize: "0.8rem",
                        fontWeight: "600",
                      }
                }
                onClick={handleNavigateInitialisation}
              >
                Mes commandes
              </button>
            </div>
          </div>
        </div>
      </div>
      {showInfo ? (
        <div>
          <div className="col mt-2 p-2 mx-2">
            <div className="col-12">
              <p
                className="m-0 p-0 px-1 text-secondary"
                style={{ fontWeight: "500", fontSize: "0.8rem" }}
              >
                Adresse email :{" "}
              </p>
            </div>
            <div className="col-12">
              <p
                className="m-0 p-0 px-1 pb-1"
                style={{ borderBottom: "1px solid #C0C0C0" }}
              >
                {user.email}
              </p>
            </div>
            <div className="col-12">
              <p
                className="m-0 p-0 px-1 fw-light"
                style={{ fontSize: "0.75rem", lineHeight: "0.9rem" }}
              >
                Pour modifier votre adresse e-mail, veuillez contacter le
                service client.
              </p>
            </div>
          </div>

          {/* Partie Address */}
          {address ===  {} || showInputAddress ? (
            <div className="col p-0 m-0 px-2 mx-2">
              <div className="col-12">
                <p
                  className="m-0 p-0 px-1 text-secondary"
                  style={{ fontWeight: "500", fontSize: "0.8rem" }}
                >
                  Adresse
                </p>
                <div className="col-12">
                  <input
                    ref={addInputs}
                    type="text"
                    className="form-control"
                    style={{
                      border: "none",
                      borderBottom: "1px solid #C0C0C0",
                    }}
                    aria-describedby="emailHelp"
                    placeholder="Saisir votre adresse..."
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="col p-0 m-0 px-2 mx-2">
              <div className="col-12">
                <p
                  className="m-0 p-0 px-1 text-secondary"
                  style={{ fontWeight: "500", fontSize: "0.8rem" }}
                >
                  Adresse{" "}
                </p>
                <div className="col-12">
                  <input
                    onClick={handleAdresse}
                    value={`${address.num ? `${address.num}, ` : ""}${
                      address.adrressName ? `${address.adrressName}, ` : ""
                    }${address.zip ? `${address.zip}, ` : ""}${
                      address.city ? `${address.city}, ` : ""
                    }${address.country}`}
                    type="text"
                    className="form-control"
                    style={{
                      border: "none",
                      borderBottom: "1px solid #C0C0C0",
                    }}
                    aria-describedby="emailHelp"
                    placeholder="Saisir votre adresse..."
                  />
                </div>
              </div>
            </div>
          )}

          {user.type ===  "particulier" ? (
            <div>
              {/* Partie LastName */}
              <div className="col p-0 m-0 px-2 mx-2">
                <div className="col-12">
                  <p
                    className="m-0 p-0 px-1 text-secondary"
                    style={{ fontWeight: "500", fontSize: "0.8rem" }}
                  >
                    Nom{" "}
                  </p>
                  {user.lastName ===  "" && !showInputLastName ? (
                    <div className="row">
                      <div
                        className="col-8"
                        onClick={() =>
                          setShowInputLastName((showInputLastName) => true)
                        }
                      >
                        <p
                          className="text-truncate m-0 p-0 pt-1 px-1 text-danger"
                          style={{ fontSize: "0.61rem" }}
                        >
                          Veuillez ajouter votre nom
                        </p>
                      </div>
                      {/* <div className='col-4'>
                            <Button variant="info btn-block py-1 bttn text-danger" onClick={() => setShowInputLastName(showInputLastName => true)} type="button" style={{background: 'transparent', border: 'none', fontSize: '0.7rem'}}>Ajouter</Button>
                        </div> */}
                    </div>
                  ) : (
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          style={{
                            border: "none",
                            borderBottom: "1px solid #C0C0C0",
                          }}
                          aria-describedby="emailHelp"
                          placeholder="Nom"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* Partie FirstName */}
              <div className="col p-0 m-0 px-2 mx-2 pt-2">
                <div className="col-12">
                  <p
                    className="m-0 p-0 px-1 text-secondary"
                    style={{ fontWeight: "500", fontSize: "0.8rem" }}
                  >
                    Prénom{" "}
                  </p>
                  {user.firstName ===  "" && !showInputFirstName ? (
                    <div className="row">
                      <div
                        className="col-8"
                        onClick={() =>
                          setShowInputFirstName((showInputFirstName) => true)
                        }
                      >
                        <p
                          className="text-truncate m-0 p-0 pt-1 px-1 text-danger"
                          style={{ fontSize: "0.61rem" }}
                        >
                          Veuillez ajouter votre prénom
                        </p>
                      </div>
                      {/* <div className='col-4'>
                            <Button variant="info btn-block py-1 bttn text-danger" onClick={() => setShowInputFirstName(showInputFirstName => true)} type="button" style={{background: 'transparent', border: 'none', fontSize: '0.7rem'}}>Ajouter</Button>
                        </div> */}
                    </div>
                  ) : (
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          style={{
                            border: "none",
                            borderBottom: "1px solid #C0C0C0",
                          }}
                          aria-describedby="emailHelp"
                          placeholder="Prénom"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div>
              {/* Partie FirstName */}
              <div className="col p-0 m-0 px-2 mx-2 pt-2">
                <div className="col-12">
                  <p
                    className="m-0 p-0 px-1 text-secondary"
                    style={{ fontWeight: "500", fontSize: "0.8rem" }}
                  >
                    Raison social
                  </p>
                  {user.firstName ===  "" && !showInputFirstName ? (
                    <div className="row">
                      <div
                        className="col-8"
                        onClick={() =>
                          setShowInputFirstName((showInputFirstName) => true)
                        }
                      >
                        <p
                          className="text-truncate m-0 p-0 pt-1 px-1 text-danger"
                          style={{ fontSize: "0.61rem" }}
                        >
                          Veuillez ajouter votre nom d'entreprise
                        </p>
                      </div>
                      {/* <div className='col-4'>
                            <Button variant="info btn-block py-1 bttn text-danger" onClick={() => setShowInputFirstName(showInputFirstName => true)} type="button" style={{background: 'transparent', border: 'none', fontSize: '0.7rem'}}>Ajouter</Button>
                        </div> */}
                    </div>
                  ) : (
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          style={{
                            border: "none",
                            borderBottom: "1px solid #C0C0C0",
                          }}
                          aria-describedby="emailHelp"
                          placeholder="Raison social"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Partie Phone */}
          <div className="col p-0 m-0 px-2 mx-2 pt-2">
            <div className="col-12">
              <p
                className="m-0 p-0 px-1 text-secondary"
                style={{ fontWeight: "500", fontSize: "0.8rem" }}
              >
                Numéro de téléphone
              </p>
              {user.tel ===  "" && !showInputPhone ? (
                <div className="row">
                  <div
                    className="col-12"
                    onClick={() => setShowInputPhone((showInputPhone) => true)}
                  >
                    <p
                      className="text-truncate m-0 p-0 pt-1 px-1 text-danger"
                      style={{ fontSize: "0.61rem" }}
                    >
                      Veuillez ajouter un numéro de téléphone
                    </p>
                  </div>
                  {/* <div className='col-4'>
                            <Button variant="info btn-block py-1 bttn text-danger" onClick={() => setShowInputPhone(showInputPhone => true)} type="button" style={{background: 'transparent', border: 'none', fontSize: '0.7rem'}}>Ajouter</Button>
                        </div> */}
                </div>
              ) : (
                <div className="col-12 pt-2">
                  <PhoneInput
                    placeholder="Saisir votre numéro"
                    inputStyle={{ width: "100%" }}
                    country="fr"
                    value={tel}
                    onChange={setTel}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="d-grid gap-2 d-flex justify-content-end mx-3 mt-2">
            <button
              className="btn btn-primary bttn text-info"
              type="submit"
              style={{
                background: "transparent",
                border: "none",
                fontSize: "0.8rem",
              }}
              onClick={handleUpdate}
            >
              ENREGISTRER
            </button>
          </div>
          <div className="d-grid gap-2 mt-4 mx-5">
            <Button
              variant="btn btn-danger btn-block text-white px-3 py-1 bttn"
              type="button"
              onClick={logOut}
            >
              Se déconnecter
            </Button>
          </div>
        </div>
      ) : (
        <div>
          {orders.map((doc) => {
            return (
              <div
                className="mx-1 px-2 py-3 pb-0 mb-3 border border-secondary rounded"
                style={{ background: "#F5F5F5" }}
              >
                <div className="row justify-content-between">
                  <div className="col-8">
                    <p>Numero de commande</p>
                  </div>
                  <div className="col-4">
                    <p className="text-end">{doc.num_order}</p>
                  </div>
                </div>
                <div className="row justify-content-between">
                  <div className="col-7">
                    <p>Date de commande</p>
                  </div>
                  <div className="col-5">
                    <p className="text-end">
                      {formatDate(doc.date_order.toDate().toISOString())}
                    </p>
                  </div>
                </div>
                <div className="row justify-content-between">
                  <div className="col-4">
                    <p>Total</p>
                  </div>
                  <div className="col-8">
                    <p className="text-end">{doc.montant} €</p>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-link p-0 m-0 mb-2"
                  onClick={() => {
                    setShowOrderDetail(true);
                    setOrder(doc);
                    getPriceDelivery(doc.montant);
                  }}
                >
                  Voir ma commande
                </button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
