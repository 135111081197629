// src/RoutesWrapper.js
import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { logPageView } from './analytics';
import Home from './pages/home/home';
import ShoppingCart from './pages/shoppingCart/shoppingCart';
import Products from './pages/products/products';
import ProductShowcase from './pages/productShowcase/productShowcase';
import Belma from './pages/belma/belma';
import Start from './pages/star/star';
import Viandes from './pages/viandes/viandes';
import Boutique from './pages/boutique/boutique';
import Delivery from './pages/delivery/delivery';
import Tracability from './pages/tracability/tracability';
import Account from './pages/account/account';
import Faq from './pages/faq/faq';
import SignUpPage from './pages/account/signUp/signUp';
import Profil from './pages/profil/profil';
import Shop from './pages/shoppingCart/shop';
import ShopSignUpPage from './pages/shoppingCart/shopSignUp';
import OrderSussecc from './components/orderSuccess';

const RoutesWrapper = ({ products, currentUser, storeState, sidebar }) => {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname);
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Belma sidebar={sidebar} />} />
      <Route path="/belma" element={<Home sidebar={sidebar} />} />
      <Route path="/star" element={<Start sidebar={sidebar} />} />
      <Route path="/success" element={<OrderSussecc sidebar={sidebar} />} />
      <Route path="/prestige_meats" element={<Viandes sidebar={sidebar} />} />
      <Route path="/belma_shop" element={<Products productsP={products} sidebar={sidebar} />} />
      <Route path="/belma_shop/:id" element={<ProductShowcase productsP={products} sidebar={sidebar} />} />
      <Route path="/belma_story" element={<Boutique sidebar={sidebar} />} />
      <Route path="/shopping_cart" element={<ShoppingCart sidebar={sidebar} />} />
      <Route path="/delivery" element={<Delivery sidebar={sidebar} />} />
      <Route path="/halal_tracability" element={<Tracability sidebar={sidebar} />} />
      <Route path="/belma_account" element={currentUser ? <Profil sidebar={sidebar} type={true} /> : <Account sidebar={sidebar} />} />
      <Route path="/faq" element={<Faq sidebar={sidebar} />} />
      <Route path="/signup_belma" element={<SignUpPage sidebar={sidebar} />} />
      <Route path="/signup_belma_shop" element={<ShopSignUpPage sidebar={sidebar} />} />
      <Route path="/orders" element={currentUser ? <Profil sidebar={sidebar} type={false} /> : <Account sidebar={sidebar} />} />
      <Route path="/shop" element={storeState.cart.length !== 0 ? <Shop sidebar={sidebar} storageState={storeState} currentU={currentUser} /> : <Products productsP={products} sidebar={sidebar} />} />
    </Routes>
  );
};

export default RoutesWrapper;
