import { useState } from "react";
import "./readMore.css";

const ReadMore = ({
  text1,
  text2,
  text3,
  text4,
  text5,
  text6,
  text7,
  text8,
  text9,
  text10,
  limit,
}) => {
  const [isReadMoreShown, setReadMoreShown] = useState(false);

  const toggleBtn = () => {
    setReadMoreShown((isReadMoreShown) => !isReadMoreShown);
  };
  return (
    <div className="read-more mx-2 mt-5">
      {isReadMoreShown ? (
        <div className="test mx-2">
          <p className="m-0 p-0 fs-5 text-start py-3">
            <span style={{ paddingRight: "5px", fontSize: "25px" }}>❝</span>
            <span style={{ fontWeight: "bold", letterSpacing: "2px" }}>
              {text1}
            </span>
            <span style={{ paddingLeft: "5px", fontSize: "25px" }}>❞</span>
          </p>
          <p className="m-0 p-0 fs-5 text-start mb-3">{text2}</p>
          <p className="m-0 p-0 fs-5 text-start mb-3">{text3}</p>
          <p className="m-0 p-0 fs-5 text-start mb-3">{text4}</p>
          <p className="m-0 p-0 fs-5 text-start mb-3">{text5}</p>
          <p className="m-0 p-0 fs-5 text-start mb-3">{text6}</p>
          <p className="m-0 p-0 fs-5 text-start mb-3">{text7}</p>
          <p className="m-0 p-0 fs-5 text-start mb-3">{text8}</p>
          <p className="m-0 p-0 fs-5 text-start mb-3">{text9}</p>
          <p className="m-0 p-0 fs-5 text-start mb-5">{text10}</p>
        </div>
      ) : (
        <div className="test mx-2">
          <p className="m-0 p-0 fs-5 text-start py-3">
            <span style={{ paddingRight: "5px", fontSize: "25px" }}>❝</span>
            <span style={{ fontWeight: "bold", letterSpacing: "2px" }}>
              {text1}
            </span>
            <span style={{ paddingLeft: "5px", fontSize: "25px" }}>❞</span>
          </p>
          <p className="m-0 p-0 fs-5 text-start mb-3">{text2}</p>
          <p className="m-0 p-0 fs-5 text-start mb-3">{text3}</p>
          <p className="m-0 p-0 fs-5 text-start mb-3">{text4}</p>
          <p className="m-0 p-0 fs-5 text-start mb-3">{text5}</p>
          <p className="m-0 p-0 fs-5 text-start mb-3">{text6.substr(0, 0)}</p>
          <p className="m-0 p-0 fs-5 text-start mb-3">{text7.substr(0, 0)}</p>
          <p className="m-0 p-0 fs-5 text-start mb-3">{text8.substr(0, 0)}</p>
          <p className="m-0 p-0 fs-5 text-start mb-3">{text9.substr(0, 0)}</p>
          <p className="m-0 p-0 fs-5 text-start mb-5">{text10.substr(0, 0)}</p>
          <div className="box1"></div>
        </div>
      )}
      <div className="d-grid gap-2 mt-0">
        <button onClick={toggleBtn} className="btn-more" type="button">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
        </button>
        {/* {isReadMoreShown ? 'Voir moins...' : 'Voir la suite...'} */}
      </div>
    </div>
  );
};

export default ReadMore;
