// Function to get cart state from local storage with error handling
const getCartStateFromLocalStorage = () => {
  try {
    const cartState = localStorage.getItem("cart");
    return cartState ? JSON.parse(cartState) : [];
  } catch (error) {
    return [];
  }
};

const getCodePromoFromLocalStorage = () => {
  try {
    const promoState = localStorage.getItem("promo");
    return promoState ? JSON.parse(promoState) : [];
  } catch (error) {
    return [];
  }
};

const getUserDetailsFromLocalStorage = () => {
  try {
    const userState = localStorage.getItem("userDetails");
    return userState ? JSON.parse(userState) : [];
  } catch (error) {
    return [];
  }
};

const getlistItemsFromLocalStorage = () => {
  try {
    const listItemState = localStorage.getItem("listItem");
    return listItemState ? JSON.parse(listItemState) : [];
  } catch (error) {
    return [];
  }
};

const getPriceDeliveryFromLocalStorage = () => {
  try {
    const priceDeliveryState = localStorage.getItem("priceDelivery");
    return priceDeliveryState ? JSON.parse(priceDeliveryState) : [];
  } catch (error) {
    return [];
  }
};

const getAmountaCompteFromLocalStorage = () => {
  try {
    const amountaCompteState = localStorage.getItem("amountaCompte");
    return amountaCompteState ? JSON.parse(amountaCompteState) : [];
  } catch (error) {
    return [];
  }
};

const getAmountFromLocalStorage = () => {
  try {
    const amountState = localStorage.getItem("amount");
    return amountState ? JSON.parse(amountState) : [];
  } catch (error) {
    return [];
  }
};

const INITIAL_STATE = {
  cart: getCartStateFromLocalStorage(),
  promo: getCodePromoFromLocalStorage(),
  userDetails: getUserDetailsFromLocalStorage(),
  amount: getAmountFromLocalStorage(),
  listItem: getlistItemsFromLocalStorage(),
  amountaCompte: getAmountaCompteFromLocalStorage(),
  priceDelivery: getPriceDeliveryFromLocalStorage(),

};

export default function cartReducer(state = INITIAL_STATE, action) {

  switch (action.type) {
    case "ADDITEM":
      const existingItemIndex = state.cart.findIndex(
        (obj) =>
          obj.titleMenu?.replace(/\s+/g, "").trim() ===
          action.payload.titleMenu?.replace(/\s+/g, "").trim()
      );
      if (existingItemIndex !== -1) {
        // If item already exists in the cart, update its quantity and priceChose
        const updatedItem = {
          ...state.cart[existingItemIndex],
          quantity:
            state.cart[existingItemIndex].quantity + action.payload.quantity,
          priceChose: action.payload.priceChose,
        };

        const updatedCart = [...state.cart];
        updatedCart.splice(existingItemIndex, 1, updatedItem);
        localStorage.setItem("cart", JSON.stringify(updatedCart));
        return {
          ...state,
          cart: updatedCart,
        };
      } else {
        // If item does not exist in the cart, add it as a new item
        const newCart = [...state.cart, action.payload];
        localStorage.setItem("cart", JSON.stringify(newCart));

        return {
          ...state,
          cart: newCart,
        };
      }

    case "UPDATEITEM":
      const itemToUpdateIndex = state.cart.findIndex(
        (obj) =>
          obj.titleMenu?.replace(/\s+/g, "").trim() ===
          action.payload.titleMenu?.replace(/\s+/g, "").trim()
      );

      if (itemToUpdateIndex !== -1) {
        // If item to update exists in the cart, update it
        const updatedCart = [...state.cart];
        updatedCart.splice(itemToUpdateIndex, 1, action.payload);
        localStorage.setItem("cart", JSON.stringify(updatedCart));
        return {
          ...state,
          cart: updatedCart,
        };
      }
      // If the item to update doesn't exist in the cart, do nothing and return the current state
      return state;



    case "DELETEITEMSHOP":
      const itemToDeleteIndex = state.cart.findIndex(
        (obj) =>
          obj.titleMenu?.replace(/\s+/g, "").trim() ===
          action.payload.titleMenu?.replace(/\s+/g, "").trim()
      );

      if (itemToDeleteIndex !== -1) {
        // If item to delete exists in the cart, remove it from the cart
        const updatedCart = [...state.cart];
        updatedCart.splice(itemToDeleteIndex, 1);
        if (updatedCart.length === 0) {
          localStorage.removeItem("cart"); // Remove the "cart" entry from local storage
        } else {
          localStorage.setItem("cart", JSON.stringify(updatedCart));
        }
        return {
          ...state,
          cart: updatedCart,
        };
      }
      // If the item to delete doesn't exist in the cart, do nothing and return the current state
      return state;

    case "DELETEITEM":
      // Clear the entire cart when the "DELETEITEM" action is dispatched
      localStorage.removeItem("cart");
      return {
        ...state,
        cart: [],
      };

    case "DELETEPROMO":
      // Clear the entire promo when the "DELETEPROMO" action is dispatched
      localStorage.removeItem("promo");
      return {
        ...state,
        promo: [],
      };

    case "CODEPROMO":
      localStorage.setItem("promo", JSON.stringify(action.payload));
      return {
        ...state,
        promo: action.payload,
      };

    case "USERDETAILS":
      localStorage.setItem("userDetails", JSON.stringify(action.payload));
      return {
        ...state,
        userDetails: action.payload,
      };

    case "LISTITEM":
      localStorage.setItem("listItem", JSON.stringify(action.payload));
      return {
        ...state,
        listItem: action.payload,
      };

    case "AMOUNTACOMPTE":
      localStorage.setItem("amountaCompte", JSON.stringify(action.payload));
      return {
        ...state,
        amountaCompte: action.payload,
      };

    case "AMOUNT":
      localStorage.setItem("amount", JSON.stringify(action.payload));
      return {
        ...state,
        amount: action.payload,
      };

    case "PRICEDELIVERY":
      localStorage.setItem("priceDelivery", JSON.stringify(action.payload));
      return {
        ...state,
        priceDelivery: action.payload,
      };

    case "DELETEAMOUNTACOMPTE":
      localStorage.removeItem("amountaCompte");
      return {
        ...state,
        amountaCompte: '',
      };

    case "DELETEPRICEDELIVERY":
      localStorage.removeItem("priceDelivery");
      return {
        ...state,
        priceDelivery: '',
      };

    case "DELETEUSERDETAILS":
      localStorage.removeItem("userDetails");
      return {
        ...state,
        userDetails: [],
      };

    case "DELETELISTITEM":
      localStorage.removeItem("listItem");
      return {
        ...state,
        listItem: [],
      };

    case "DELETEAMOUNT":
      localStorage.removeItem("amount");
      return {
        ...state,
        amount: [],
      };
    default:
      return state;
  }
}
