import React, { useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import UsersDataService from "../data/users.services";
import random from "random";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { CURRENCY_CODE, ACOMPTE } from "../utils/constants"

const countries = require('countries-list').countries;

const functions = getFunctions();
var randomstring = require("randomstring");
const notifyClient = httpsCallable(functions, "notifyClient");

const formatedDate = () => {
  let now = new Date();
  // Formatage de la date et de l'heure (par exemple, 'YYYY-MM-DD HH:MM')
  let year = now.getFullYear();
  let month = (now.getMonth() + 1).toString().padStart(2, '0'); // Les mois sont de 0 à 11
  let day = now.getDate().toString().padStart(2, '0');
  let hours = now.getHours().toString().padStart(2, '0');
  let minutes = now.getMinutes().toString().padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

const formatProduct = (product, reduction) => {
  return {
    name: product.name,
    unit_amount: { value: product.name !== ACOMPTE ? truncateFloat(truncateFloat(product.priceItem) - (truncateFloat(product.priceItem) * reduction / 100)) : truncateFloat(product.priceItem), currency_code: CURRENCY_CODE },
    quantity: product.qty
  };
};

// Fonction pour formater une liste de produits
const formatProductsList = (products, reduction) => {
  return products.map(product => formatProduct(product, reduction));
};

function truncateFloat(number) {
  let numberString = number.toString();
  let decimalIndex = numberString.indexOf('.');
  if (decimalIndex !== -1) {
      return numberString.substring(0, decimalIndex + 3); // +3 pour inclure le point et deux décimales
  }
  return numberString;
}

function getCountryCode(countryName) {
  for (const code in countries) {
    if (countries[code].name === countryName) {
      return code;
    }
  }
  return null; // Return null if the country name is not found
}
function calculateTotal(items) {
  let total = 0;

  // Parcourir chaque article dans le tableau
  items.forEach(item => {
      // Extraire le prix unitaire et la quantité de chaque article
      const unitPrice = parseFloat(item.unit_amount.value);
      const quantity = item.quantity;

      // Calculer le montant total pour cet article
      const subtotal = unitPrice * quantity;

      // Ajouter le subtotal au total général
      total += subtotal;
  });

  return total.toFixed(2);  
}

function PaymentForm(props) {
  const storeState = useSelector((state) => state);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();


  let userD = {}
  userD = storeState.userDetails
  if (typeof userD === "string") {
    userD = JSON.parse(userD);
  }

  const handleError = (err) => {
    userD = storeState.userDetails
    if (typeof userD === "string") {
      userD = JSON.parse(userD);
    }
    setErrorMessage("Une erreur est survenue lors du processus de paiement. Veuillez réessayer.");

    notifyClient({
      order: JSON.stringify(err, Object.getOwnPropertyNames(err)),
      detailsClient: JSON.stringify(userD, Object.getOwnPropertyNames(userD)),
      subject: 'Error paiement - ' + formatedDate(),
      email: "guetatanouar@gmail.com"
    });
    
  };

  const createOrder = async (data, actions) => {


    let finalAmount = parseFloat(storeState.amount) + parseFloat(storeState.priceDelivery) + parseFloat(storeState.amountaCompte)

    let promo = storeState.promo ? storeState.promo : {}
    let reduction = 0
    console.log("====finalAmount=====", finalAmount)
    console.log("====promo=====", promo)

    if (promo && promo.reduction && promo.reduction !== 0) {
      finalAmount = parseFloat(storeState.amountaCompte) + parseFloat(storeState.priceDelivery) + (parseFloat(storeState.amount) - (parseFloat(storeState.amount) * parseInt(promo.reduction) / 100))
      reduction = promo.reduction
      console.log("====finalAmount with reduction=====", finalAmount)

    }

    finalAmount = truncateFloat(finalAmount)
    let formattedProducts = formatProductsList(storeState.listItem, reduction);

    let delivryItem = {
      name: "Delivery Price",
      unit_amount: { value: storeState.priceDelivery, currency_code: CURRENCY_CODE },
      quantity: 1
    }

    formattedProducts = formattedProducts.concat(delivryItem)
     
    let grandTotal = calculateTotal(formattedProducts)
    
  console.log("formattedProducts",formattedProducts)
  console.log("storeState.listItem",storeState.listItem)

    console.log("grandTotal",grandTotal)

    grandTotal = truncateFloat(grandTotal)
    console.log("truncateFloat grandTotal",grandTotal)

    let country_Code = "FR"
    if (!userD.address.country_Code && userD.address.country)
      country_Code = getCountryCode(userD.address.country)

    let infoLastName = userD.lastName ? userD.lastName : "Alin"
    let infoFirstName = userD.firstName ? userD.firstName : "Dupin"
    let billing_address = userD.address && userD.address.num && userD.address.adrressName ? userD.address.num + " " + userD.address.adrressName : "123 Avenue de Paris"
    let city = userD.address && userD.address.city ? userD.address.city : "Paris"
    let area_Code = userD.address && userD.address.area_Code ? userD.address.area_Code : "IDF"
    country_Code = userD.address && userD.address.country_Code ? userD.address.country_Code : country_Code
    let postal_code = userD.address && userD.address.zip ? userD.address.zip : "94160"

    notifyClient({
      order: 'userD:' + JSON.stringify(userD) + ' grandTotal:' + grandTotal + ' reduction:' + reduction,
      detailsClient: JSON.stringify(formattedProducts),
      subject: 'before paiement - ' + formatedDate(),
      email: "guetatanouar@gmail.com"
    });

    const purchaseUnits = [{
      amount: {
        value: grandTotal, // Specify the amount for the transaction
        breakdown: {
          item_total: {
            value: grandTotal, currency_code: CURRENCY_CODE
          }
        }
      },
      items: formattedProducts,
      shipping: {
        name: {
          full_name: infoLastName + ' ' + infoFirstName // Specify the full name for shipping
        },
        address: {
        }
      }
    }];

    purchaseUnits[0].shipping.address.admin_area_1 = area_Code;
    purchaseUnits[0].shipping.address.admin_area_2 = city;
    purchaseUnits[0].shipping.address.address_line_1 = billing_address;
    purchaseUnits[0].shipping.address.postal_code = postal_code;
    purchaseUnits[0].shipping.address.country_code = country_Code;



    return actions.order
      .create({
        purchase_units: purchaseUnits,
        payer: {
          name: {
            given_name: infoLastName,
            surname: infoFirstName
          },
          address: {
            address_line_1: billing_address,
            admin_area_1: area_Code,
            admin_area_2: city,
            postal_code: postal_code,
            country_code: country_Code
          },
          email_address: userD && userD.mail ? userD.mail : 'test@gmail.com',
          phone: {
            phone_type: 'MOBILE',
            phone_number: {
              national_number: userD && userD.tel ? userD.tel : '1234567890'
            }
          }

        }
      })
      .then((orderID) => {
        return orderID;
      });
  };
  // handles when a payment is confirmed for paypal
  const onApprove = async (data, actions) => {

    return actions.order.capture().then(async function (details) {
      const { payer } = details
      let userD = storeState.userDetails ? storeState.userDetails : {}
      let promo = storeState.promo ? storeState.promo : {}

      let codePromo = ""
      let ReductionInfo = 0

      if (typeof userD === "string") {
        userD = JSON.parse(userD);
      }
      if (typeof promo === "string") {
        promo = JSON.parse(promo);
      }

      if (promo && promo.reduction) {
        codePromo = promo.codepromo
        ReductionInfo = promo.reduction
      }

      const str = randomstring.generate({
        length: 2,
        capitalization: "uppercase",
        charset: "alphabetic",
      });
      const orderNum = `${random.int(0, 9999)}${str}${random.int(0, 20)}`;

      const newOrder = {
        date_order: new Date(),
        email_order: payer.email_address,
        lastName_order: payer.name.given_name,
        firstName_order: payer.name.surname,
        montant: parseFloat(storeState.amount) + parseFloat(storeState.amountaCompte),
        reduction: ReductionInfo,
        codepromo: codePromo,
        infopriceDelivery: storeState.priceDelivery,
        tel_order: userD.tel ? userD.tel : "",
        num_order: orderNum,
        delivery_date: new Date(),
        items: storeState.listItem ? storeState.listItem : {},
        type_order: userD.type ? userD.type : "",
        address: userD.address ? JSON.stringify(userD.address) : {}
      };

      notifyClient({
        order: orderNum,
        detailsClient: '',
        subject: '',
        email: payer.email_address
      });

      await UsersDataService.addOrder(newOrder);

      dispatch({
        type: "DELETEITEM",
      });
      dispatch({
        type: "DELETEPROMO",
      });
      dispatch({
        type: "DELETEPRICEDELIVERY",
      });
      dispatch({
        type: "DELETEAMOUNTACOMPTE",
      });
      dispatch({
        type: "DELETEUSERDETAILS",
      });
      dispatch({
        type: "DELETEAMOUNT",
      });
      dispatch({
        type: "DELETELISTITEM",
      });
      navigate("/success");

    })

  };

  let ReductionInfo = 0;
  let codePromo = "";

  if (storeState.promo) {
    if (typeof (storeState.promo) === 'string') {
      ReductionInfo = JSON.parse(storeState.promo).reduction
      codePromo = JSON.parse(storeState.promo).codepromo
    } else {
      ReductionInfo = storeState.promo.reduction
      codePromo = storeState.promo.codepromo
    }
  }

  let listeItem = [];
  let listePriceIdSelected = [];
  for (const item of storeState.cart) {
    const itemPrice = {
      qty: item.quantity,
      name: item.titleMenu,
      priceItem: item.priceChose,
      option: item.option,
    };
    const itemPriceId = { price: item.priceId, quantity: item.quantity, amount: item.priceChose };
    listeItem.push(itemPrice);
    listePriceIdSelected.push(itemPriceId);
  }

  return (
    <>

      <div className="container-paypal">
        {errorMessage && (
          <div style={{
            color: 'white',
            backgroundColor: '#d1281b',
            padding: '10px',
            marginBottom: '10px',
            borderRadius: '5px',
            textAlign: 'center'
          }}>
            {errorMessage}
          </div>
        )}
        {props.fct ? <PayPalButtons
          style={{
            color: "black",
            label: "pay",
            tagline: false,
            layout: "horizontal",
            align: "center"
          }}
          fundingSource={"card"}
          onError={handleError}
          createOrder={createOrder}
          onApprove={onApprove}

        /> : ""}

      </div>

    </>
  );
}

export default PaymentForm;
