import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import image1 from "../../Images/VIANDE/1.jpg";
import image2 from "../../Images/VIANDE/2.png";
import image3 from "../../Images/VIANDE/3.png";
import AOS from "aos";
import "aos/dist/aos.css";
import image4 from "../../Images/VIANDE/5.png";

export default function GroupeCart() {
  const [decouvrir1, setDecouvrir1] = useState(false);
  const [decouvrir2, setDecouvrir2] = useState(false);
  const [decouvrir3, setDecouvrir3] = useState(false);
  const [decouvrir4, setDecouvrir4] = useState(false);
  const navigate = useNavigate();
 
  const handleClick = (numberDec) => {
    switch (numberDec) {
      case 1:
        setDecouvrir1((decouvrir1) => true);
        setDecouvrir2((decouvrir2) => false);
        setDecouvrir3((decouvrir3) => false);
        setDecouvrir4((decouvrir4) => false);
        break;
      case 2:
        setDecouvrir1((decouvrir1) => false);
        setDecouvrir2((decouvrir2) => true);
        setDecouvrir3((decouvrir3) => false);
        setDecouvrir4((decouvrir4) => false);
        break;
      case 3:
        setDecouvrir1((decouvrir1) => false);
        setDecouvrir2((decouvrir2) => false);
        setDecouvrir3((decouvrir3) => true);
        setDecouvrir4((decouvrir4) => false);
        break;
      case 4:
        setDecouvrir1((decouvrir1) => false);
        setDecouvrir2((decouvrir2) => false);
        setDecouvrir3((decouvrir3) => false);
        setDecouvrir4((decouvrir4) => true);
        break;
      default:
        setDecouvrir1((decouvrir1) => false);
        setDecouvrir2((decouvrir2) => false);
        setDecouvrir3((decouvrir3) => false);
        setDecouvrir4((decouvrir4) => false);
    }
  };
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div style={{ marginTop: "80px", overflow: "hidden" }}>
      <div className="row justify-content-around m-1 mb-3 text-center">
        <div
          className="col-5 p-0 rounded position-relative"
          style={{ height: "150px", overflow: "hidden" }}
          onClick={() => handleClick(1)}
        >
          {decouvrir1 ? (
            <img
              src={image1}
              alt="accueil shop"
              className="img-fluid rounded photo-gris"
            />
          ) : (
            <img
              src={image1}
              alt="accueil shop"
              className="img-fluid rounded mx-auto"
            />
          )}
          <img
            src={image1}
            alt="accueil shop"
            className="img-fluid rounded photo-gris"
          />
          {decouvrir1 && (
            <div className="position-absolute top-50 start-50 translate-middle rounded hover_link_groupe">
              <Link to="/belma_shop" className="text-light">
                Découvrir
              </Link>
            </div>
          )}
        </div>
        <div
          className="col-5 p-0 rounded position-relative d-flex justify-content-center align-items-center"
          style={{ height: "150px", overflow: "hidden" }}
          onClick={() => handleClick(2)}
        >
          {decouvrir2 ? (
            <img
              src={image2}
              alt="accueil shop"
              className="img-fluid rounded photo-gris "
            />
          ) : (
            <img
              src={image2}
              alt="accueil shop"
              className="img-fluid rounded"
            />
          )}
          {decouvrir2 && (
            <div className="position-absolute top-50 start-50 translate-middle rounded hover_link_groupe">
              <Link className="text-light" to="/belma_shop">
                Découvrir
              </Link>
            </div>
          )}
        </div>
      </div>

      <div className="row justify-content-around m-1 text-center">
        <div
          className="col-5 p-0 rounded position-relative"
          style={{ height: "150px", overflow: "hidden" }}
          onClick={() => handleClick(3)}
        >
          {decouvrir3 ? (
            <img
              src={image3}
              alt="accueil shop"
              className="img-fluid rounded photo-gris"
            />
          ) : (
            <img
              src={image3}
              alt="accueil shop"
              className="img-fluid rounded"
            />
          )}
          {decouvrir3 && (
            <div className="position-absolute top-50 start-50 translate-middle rounded hover_link_groupe">
              <Link className="text-light" to="/belma_shop">
                Découvrir
              </Link>
            </div>
          )}
        </div>
        <div
          className="col-5 p-0 rounded position-relative"
          style={{ height: "150px", overflow: "hidden" }}
          onClick={() => handleClick(4)}
        >
          {decouvrir4 ? (
            <img
              src={image4}
              alt="accueil shop"
              className="img-fluid rounded photo-gris"
            />
          ) : (
            <img
              src={image4}
              alt="accueil shop"
              className="img-fluid rounded"
            />
          )}
          {decouvrir4 && (
            <div className="position-absolute top-50 start-50 translate-middle rounded hover_link_groupe">
              <Link className="text-light" to="/belma_shop">
                Découvrir
              </Link>
            </div>
          )}
          {/* <div style={{height: '150px', width: '150px', background: 'red'}}>

                </div> */}
          {/* <img src={imgHomeShopTest} alt="accueil shop" style={{height: '350px', maxWidth: '100%'}}/>
                {decouvrir4 && <div className='position-absolute top-0 start-0 rounded hover_link'><Link to='/belma_shop'>Découvrir</Link></div>} */}
        </div>
      </div>
    </div>
  );
}
