import {
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  setDoc,
  updateDoc,
  query
} from "firebase/firestore";
import { db } from "../firebase-config";

const ordersCollectionRef = collection(db, "orders");
const usersCollectionRef = collection(db, "users");

class UsersDataService {
  getAllUsers = (title) => {
    const q = query(
      usersCollectionRef
    );
    return getDocs(q);
  };
  getUser = (id) => {
    const userDoc = doc(db, "users", id);
    return getDoc(userDoc);
  };
  addUser = (id, newUser) => {
    return setDoc(doc(db, "users", id), newUser);
  };
  updateUser = (lastNameUpdate, firstNameUpdate, idDoc) => {
    return updateDoc(doc(db, "users", idDoc), {
      lastName: lastNameUpdate,
      firstName: firstNameUpdate,
    });
  };
  updateUserTel = (tel, idDoc) => {
    return updateDoc(doc(db, "users", idDoc), {
      tel: tel,
    });
  };
  updateAdress = (address, idDoc) => {
    return updateDoc(doc(db, "users", idDoc), {
      address: address,
    });
  };
  updateUserOrder = (address, idDoc) => {
    return updateDoc(doc(db, "users", idDoc), {
      address: address.address,
      firstName: address.firstName,
      lastName: address.lastName,
      tel: address.tel,
    });
  };
  addOrder = (newOrder) => {
    return addDoc(ordersCollectionRef, newOrder);
  };
}

export default new UsersDataService();
