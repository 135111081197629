import React, { useState, useEffect, useCallback } from "react";
import { IoRemoveCircleOutline, IoAddCircleOutline } from "react-icons/io5";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faClose, faGift } from "@fortawesome/free-solid-svg-icons";
import CodePromoDataService from "../../data/code.promo.services"
import UsersDataService from "../../data/users.services";
import { getFunctions, httpsCallable } from "firebase/functions";
import { ACOMPTE } from '../../utils/constants'

const functions = getFunctions();

const DeliveryModal = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} dialogClassName="modal-dialog-centered">
      <Modal.Header closeButton>
        <Modal.Title>Frais de livraison</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Nos frais de livraison varient en fonction du total de la commande :
        </p>
        <table className="table">
          <thead>
            <tr>
              <th>Total de la Commande</th>
              <th>Frais de Livraison</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>0€ à 50€</td>
              <td>25€</td>
            </tr>
            <tr>
              <td>50€ à 140€</td>
              <td>20€</td>
            </tr>
            <tr>
              <td>Plus de 140€</td>
              <td>Gratuit</td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
};

const DeliveryDayModal = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} dialogClassName="modal-dialog-centered">
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <p className="m-0 pt-0 px-2 text-center">
          Chers clients, <br />
          Veuillez prendre en compte les informations ci-dessous pour vos prochaines commandes :
        </p>
        <div className="px-1 mt-3">
          <table className="table m-0">
            <thead>
              <tr>
                <th className="text-center">Jour de votre commande</th>
                <th className="text-center">Jour de votre livraison</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="S.No" className="text-center m-0 p-auto px-0">
                  Lundi
                </td>
                <td data-label="Name" className="text-center">
                  Mercredi ou Jeudi
                </td>
              </tr>
              <tr>
                <td data-label="S.No" className="text-center">
                  Mardi
                </td>
                <td data-label="Name" className="text-center">
                  Jeudi ou Vendredi
                </td>
              </tr>

              <tr>
                <td data-label="S.No" className="text-center">
                  Mercredi
                </td>
                <td data-label="Name" className="text-center">
                  Vendredi ou Samedi
                </td>
              </tr>

              <tr>
                <td data-label="S.No" className="text-center">
                  Jeudi avant 12H
                </td>
                <td data-label="Name" className="text-center">
                  Vendredi ou Samedi
                </td>
              </tr>

              <tr>
                <td data-label="S.No" className="text-center">
                  Jeudi aprés 12H
                </td>
                <td data-label="Name" className="text-center">
                  Samedi ou Lundi
                </td>
              </tr>

              <tr>
                <td data-label="S.No" className="text-center">
                  Vendredi
                </td>
                <td data-label="Name" className="text-center">
                  Mercredi ou Jeudi
                </td>
              </tr>

              <tr>
                <td data-label="S.No" className="text-center">
                  Samedi
                </td>
                <td data-label="Name" className="text-center">
                  Mercredi ou Jeudi
                </td>
              </tr>

              <tr>
                <td data-label="S.No" className="text-center">
                  Dimanche
                </td>
                <td data-label="Name" className="text-center">
                  Mercredi ou Jeudi
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const ShoppingCartItem = ({ item, index, onDelete, onUpdate }) => {
  return (
    <div className="row align-items-center mx-0">
      <div
        className="col py-1"
        style={{
          paddingLeft: "10px",
          fontSize: "0.9rem",
          border: "1px solid #FFFFFF",
          backgroundColor: "#FFFFFF",
        }}
      >
        <span style={{ fontWeight: "600" }}>{item.titleMenu}</span>
        <span style={{ fontWeight: "400", fontSize: "0.8rem" }}>
          {" "}
          - {item.option}
        </span>
        <p
          className="m-0 p-0"
          style={{ fontWeight: "Bold", color: "rgb(193, 157, 86)", cursor: "pointer" }}
          onClick={() => onDelete(index)}
        >
          Retirer
        </p>
      </div>
      <div className="col-auto px-2 py-1">
        <p>
          <IoRemoveCircleOutline
            onClick={() => onUpdate(false, index)}
            color="rgb(14, 14, 14)"
            className="mb-1"
            style={{ cursor: "pointer" }}
          />
          <span className="px-1">{item.quantity}</span>
          <IoAddCircleOutline
            onClick={() => onUpdate(true, index)}
            color="rgb(14, 14, 14)"
            className="mb-1"
            style={{ cursor: "pointer" }}
          />
        </p>
      </div>
      <div className="col-auto px-2 py-1">
        <p>{(item.quantity * item.priceChose).toFixed(2)} €</p>
      </div>
    </div>
  );
};

export default function ShoppingCart() {
  const storeState = useSelector((state) => state);
  const [priceDelivery, setPriceDelivery] = useState(0);
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);
  const [showDeliveryDayModal, setShowDeliveryDayModal] = useState(true);
  const [expiredCode, setExpiredCode] = useState(null);
  const [notExistedCode, setNotExistedCode] = useState(false);
  const [disableApply, setDisableApply] = useState(false);
  const [codepromo, setCodePromo] = useState(null);
  const [reduction, setReduction] = useState(0);
  const toggleDeliveryModal = () => {
    setShowDeliveryModal(!showDeliveryModal);
  };

  const showMessageAfterApplyPromo = (message) => {
    return <div style={{
      background: "#000",
      padding: "6px",
      width: "300px",
      margin: "9px auto 10px auto"
    }}>
      <div style={{
        float: "right", cursor: "pointer", paddingRight: "5px", padding: "1px",
        color: "white"
      }}>
        {" "}
        <FontAwesomeIcon
          onClick={() => setDisableApply(false)}
          icon={faClose}
        />
      </div>
      <div className="text-secondary text-black-center apply-ok">
        {message}
      </div>
    </div>
  }
  const getCodePromoAnddiableBtn = () => {
    setDisableApply(true)
    getCodesPromo()
  }

  const setCodePromoAndEnableBtn = (value) => {
    setDisableApply(false)
    setCodePromo(value)
  }

  const toggleDeliveryDayModal = () => {
    setShowDeliveryDayModal(!showDeliveryDayModal);
  };

  const getCodesPromo = async () => {
    try {
      const data = await CodePromoDataService.getAllCodes();
      let promo = {}

      promo.codespromofromdb = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      promo.codepromo = codepromo
      let Reduction = 0
      const element = document.getElementById('finalPriceValue');
      let finalPrice = 0
      if (promo.codespromofromdb && promo.codespromofromdb.length !== 0) {
        const promoCodeObject = promo.codespromofromdb.find(code => code.title === promo.codepromo);
        if (promoCodeObject) {
          Reduction = promoCodeObject ? promoCodeObject.reduction : 0;
          const currentDateTime = new Date().getTime();
          // Calculate milliseconds equivalent
          const milliseconds = (promoCodeObject.expirationdate.seconds * 1000) + (promoCodeObject.expirationdate.nanoseconds / 1000000);
          // Convert to JavaScript Date object and get time in milliseconds
          const expiredate = new Date(milliseconds);
          let expirationDateTime = expiredate.getTime()
          if (expirationDateTime > currentDateTime && Reduction !== 0) {
            setReduction(Reduction)
            setExpiredCode(false)
            setNotExistedCode(false)
            finalPrice = priceDelivery + (totalPrice - totalPrice * parseInt(Reduction) / 100)
            if (element) {
              element.textContent = Number.parseFloat(finalPrice).toFixed(2);
            }
          } else if (expirationDateTime <= currentDateTime) {
            setExpiredCode(true)
            setNotExistedCode(false)
            setReduction(0)
            if (element) {
              element.textContent = Number.parseFloat(totalPrice + priceDelivery).toFixed(2);
            }
          }
        } else {
          setNotExistedCode(true)
          setExpiredCode(false)
          setReduction(null)
          setReduction(0)
          if (element) {
            element.textContent = Number.parseFloat(totalPrice + priceDelivery).toFixed(2);
          }
        }
        promo.reduction = Reduction
      }
      dispatch({
        type: "CODEPROMO",
        payload: promo,
      });
    } catch (error) {
      console.error("Error fetching codes promo:", error);
    }
  };

  const calculateTotalPrice = useCallback(() => {
    let totalPrice = 0;
    for (const item of storeState.cart) {
      const itemPrice = item.priceChose * item.quantity;
      totalPrice += itemPrice;
    }
    return totalPrice;
  }, [storeState.cart]);

  const calculateTotalPriceWithoutAcompte = useCallback(() => {
    let totalPrice = 0;
    for (const item of storeState.cart) {
      //pas de frais de livraion pour
      if (item.titleMenu !== ACOMPTE) {
        const itemPrice = item.priceChose * item.quantity;
        totalPrice += itemPrice;
      }
    }
    return totalPrice;
  }, [storeState.cart]);

  useEffect(() => {
    const totalPrice = calculateTotalPriceWithoutAcompte();
    if (totalPrice === 0 || totalPrice >= 140) {
      setPriceDelivery(0);
    } else if (totalPrice > 0 && totalPrice < 50) {
      setPriceDelivery(25);
    } else if (totalPrice >= 50 && totalPrice < 140) {
      setPriceDelivery(20);
    }
  }, [storeState.cart, calculateTotalPriceWithoutAcompte]);



  const dispatch = useDispatch();



  const updateItemCart = (add, index) => {
    const itemUpdate = { ...storeState.cart[index] };
    if (add) {
      itemUpdate.quantity += 1;
    } else {
      if (itemUpdate.quantity > 1) {
        itemUpdate.quantity -= 1;
      } else {
        dispatch({
          type: "DELETEITEMSHOP",
          payload: itemUpdate,
        });
      }
    }
    dispatch({
      type: "UPDATEITEM",
      payload: itemUpdate,
    });
  };

  const deleteItemCart = (index) => {
    const itemUpdate = { ...storeState.cart[index] };
    dispatch({
      type: "DELETEITEMSHOP",
      payload: itemUpdate,
    });
  };


  const sendmail = async () => {

    const data = await UsersDataService.getAllUsers();
    let users = {}

    users = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    const notifyClient = httpsCallable(functions, "notifyClient");
    notifyClient({
      order: "1233"
    });
    users.forEach((user) => {
      if (user.email === "guetatanouar@gmail.com") {


      }
    });
  };

  const totalPrice = calculateTotalPrice();
  const totalPriceWithoutAcompte = calculateTotalPriceWithoutAcompte();
  let acomptePrice = totalPrice - totalPriceWithoutAcompte;

  return (
    <div className="mt-5" style={{ background: "#F8F8F8", minHeight: "90vh" }}>
      {storeState.cart.length !== 0 ? (
        <div>
          <p className="pt-5 px-3" style={{ fontWeight: "600" }}>
            Votre Panier
          </p>

          <p
            className="m-0 p-0"
            style={{ fontWeight: "Bold", color: "rgb(193, 157, 86)", cursor: "pointer", display: "none" }}
            onClick={() => sendmail()}
          >
            send mail
          </p>


          <div className="bg-white mt-5 border-top pt-2 pb-3 scrollHeight">
            {storeState.cart.map((item, index) => (
              <ShoppingCartItem
                key={index}
                item={item}
                index={index}
                onDelete={deleteItemCart}
                onUpdate={updateItemCart}
              />
            ))}
            <div
              style={{ height: "1px", width: "95%" }}
              className="border-top mx-auto mt-3 mb-2"
            ></div>
            <div className="col-12 d-flex justify-content-between row customPadding">
              <div
                className="col-3 m-0 p-2 px-2 text-secondary text-black-center">
                <FontAwesomeIcon
                  icon={faGift}
                /> Code promo
              </div>
              <div className="col-5">
                <input
                  type="text"
                  className="form-control bg-input"
                  style={{
                    border: "none",
                    borderBottom: "1px solid #C0C0C0",
                    fontSize: "14px",
                    padding: "7px"
                  }}

                  aria-describedby="emailHelp"
                  placeholder="Entrer votre code promo"
                  value={codepromo ? codepromo : ''}
                  onChange={(e) => setCodePromoAndEnableBtn(e.target.value)}
                />
              </div>
              {!disableApply && codepromo ? <div className="col-4 m-0 p-2 px-3 text-secondary text-apply-center"
                style={{ fontWeight: "Bold", color: "rgb(193, 157, 86)", cursor: "pointer" }}
                onClick={() => getCodePromoAnddiableBtn()}
              >
                Appliquer
              </div> :
                <div className="col-4 m-0 p-2 px-3 text-secondary text-apply-center-disable"
                  style={{ fontWeight: "Bold", color: "rgb(193, 157, 86)", cursor: "pointer" }}
                >
                  Appliquer
                </div>}
            </div>
            {reduction !== 0 && disableApply ?
              showMessageAfterApplyPromo("Code promo appliqué!") : null}

            {expiredCode && disableApply ?
              showMessageAfterApplyPromo("Code promo expiré!") : null}

            {notExistedCode && disableApply && reduction === 0 ?
              showMessageAfterApplyPromo("Code promo non trouvé!") : null}
            <div
              style={{ height: "1px", width: "95%" }}
              className="border-top mx-auto mt-3 mb-2"
            ></div>
            <div className="d-flex justify-content-between">
              <p className="px-2 m-0">Réduction</p>
              <p className="px-2 m-0" style={{ color: "green", fontSize: "bold" }}>
                - {reduction} %
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="px-2 m-0">Sous total</p>
              <p className="px-2 m-0">{(acomptePrice + (totalPriceWithoutAcompte - totalPriceWithoutAcompte.toFixed(2)
                * parseInt(reduction) / 100)).toFixed(2)} €</p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="px-2 m-0">Frais de livraison</p>
              <p className="px-2 m-0">
                <span style={{ cursor: "pointer", paddingRight: "5px" }}>
                  {" "}
                  <FontAwesomeIcon
                    onClick={toggleDeliveryModal}
                    icon={faInfoCircle}
                  />
                </span>
                {Number.parseFloat(priceDelivery).toFixed(2)} €
              </p>
            </div>


            <DeliveryModal
              show={showDeliveryModal}
              onHide={toggleDeliveryModal}
            />
            <DeliveryDayModal
              show={showDeliveryDayModal}
              onHide={toggleDeliveryDayModal}
            />
          </div>
          <div
            className="fixed-footer fixed-bottom bg-white border-top py-auto align-items-center justify-content-center"
          >
 
            <div className="d-grid gap-2 m-3 mx-3">

              <div className="d-flex justify-content-between pb-0">
                <p className="px-2 m-0">TOTAL À RÉGLER</p>
                <p className="px-2 m-0" style={{ fontWeight: "600" }} >
                  <span id="finalPriceValue">{(
                    Number.parseFloat((priceDelivery + acomptePrice + (totalPriceWithoutAcompte - totalPriceWithoutAcompte.toFixed(2)
                      * parseInt(reduction) / 100)).toFixed(2))
                  ).toFixed(2)}</span>
                  {" "}€
                </p>

              </div>



              <Link
                to="/shop"
                style={{ textDecoration: "none", color: "#fff" }}
                className="p-0 m-0"
              >
                <div className="d-grid gap-2">
                  <button
                    className="btn btn-primary py-2 mx-3"
                    style={{
                      background: "#c19d56",
                      border: "1px solid #c19d56",
                      fontWeight: "600",
                    }}
                  >
                    Finaliser la commande
                  </button>
                </div>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="position-relative"
          style={{ height: "90vh", overflow: "hidden" }}
        >
          <div className="text-center position-absolute top-50 start-50 translate-middle">
            Votre panier est vide
          </div>
        </div>
      )}
    </div>
  );
}
