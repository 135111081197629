// src/analytics.js
import GA4React from 'ga-4-react';

let ga4react;

export const initGA = async () => {
  ga4react = new GA4React('G-3XL0N2L60W'); // Replace with your GA4 measurement ID
  try {
    await ga4react.initialize();
    console.log('GA4 initialized');
  } catch (err) {
    console.error('GA4 initialization failed', err);
  }
};

export const logPageView = (path) => {
  if (ga4react) {
    ga4react.pageview(path);
  } else {
    console.error('GA4React is not initialized');
  }
};
