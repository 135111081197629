import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineInstagram, AiOutlineFacebook } from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import "./footer.css"; // Assume you have a CSS file named Footer.css for styling

export default function Footer() {
  return (
    <footer className="footer-container">
      <div className="footer-links">
        <Link to="/faq">FAQ</Link>
        <a
          href="https://drive.google.com/file/d/1CCEEHjOJmhzYP3gIDT617I6MBj1wZtR9/view?usp=share_link"
          target="_blank"
          rel="noopener noreferrer"
        >
          CGV
        </a>
        <Link to="/belma_story">LA BOUTIQUE</Link>
      </div>
      <div className="footer-social">
        <a
          href="https://www.instagram.com/belma_boucherie/?hl=fr"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineInstagram size={25} color="#c19d56" />
          <span className="sr-only">Instagram</span>
        </a>
        <a
          href="https://www.facebook.com/belmaboucherie/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiOutlineFacebook size={25} color="#c19d56" />
          <span className="sr-only">Facebook</span>
        </a>
        <a
          href="https://www.tiktok.com/@belma_boucherie"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTiktok size={18} color="#c19d56" />
          <span className="sr-only">TikTok</span>
        </a>
      </div>
    </footer>
  );
}
