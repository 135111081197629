// src/App.js
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/navbar/navbar";
import FloatingCart from "./components/floatingCart/floatingCart";
import Home from "./pages/home/home";
import ShoppingCart from "./pages/shoppingCart/shoppingCart";
import Products from "./pages/products/products";
import ProductShowcase from "./pages/productShowcase/productShowcase";
import Sidebar from "./components/navbar/sidebar";
import Backdrop from "./components/navbar/backdrop";
import { useContext, useEffect, useState } from "react";
import ProductsDataService from "./data/products.services";
import Belma from "./pages/belma/belma";
import Start from "./pages/star/star";
import Viandes from "./pages/viandes/viandes";
import Boutique from "./pages/boutique/boutique";
import Delivery from "./pages/delivery/delivery";
import Tracability from "./pages/tracability/tracability";
import Account from "./pages/account/account";
import Faq from "./pages/faq/faq";
import FloatingProfil from "./components/profil/profil";
import SignUpPage from "./pages/account/signUp/signUp";
import Profil from "./pages/profil/profil";
import { UserContext } from "./data/userContext";
import Shop from "./pages/shoppingCart/shop";
import { useSelector } from "react-redux";
import ShopSignUpPage from "./pages/shoppingCart/shopSignUp";
import OrderSussecc from "./components/orderSuccess";
import ScrollToTop from "./components/scrollToTop";
import { initGA } from './analytics';
import RoutesWrapper from './RoutesWrapper';

function App() {
  const { currentUser } = useContext(UserContext);
  const [sidebar, setSidebar] = useState(false);
  const [products, setProducts] = useState([]);
  const storeState = useSelector((state) => state);

  useEffect(() => {
    initGA();
  }, []);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    const data = await ProductsDataService.getAllProducts();
    setProducts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const toggleSidebar = () => {
    setSidebar((prevState) => !prevState);
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navbar openSidebar={toggleSidebar} />
      <Backdrop sidebar={sidebar} closeSidebar={toggleSidebar} />
      <Sidebar sidebar={sidebar} closeSidebar={toggleSidebar} />
      <FloatingCart />
      <FloatingProfil />
      <RoutesWrapper 
        products={products}
        currentUser={currentUser}
        storeState={storeState}
        sidebar={sidebar}
      />
    </BrowserRouter>
  );
}

export default App;
