import React, { useEffect } from "react";
import Footer from "../../components/footer/footer";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Slideshow from "../../components/slide/slideshow";
import image1 from "../../Images/LABOUTIQUE/1.jpg";
import image2 from "../../Images/LABOUTIQUE/2.jpg";
import image3 from "../../Images/LABOUTIQUE/3.jpg";
import image4 from "../../Images/LABOUTIQUE/4.jpg";
import image5 from "../../Images/LABOUTIQUE/5.jpg";

const slides = [
  { src: image1, alt: "Slide 1" },
  { src: image2, alt: "Slide 2" },
  { src: image3, alt: "Slide 3" },
  { src: image4, alt: "Slide 4" },
  { src: image5, alt: "Slide 5" },
];

export default function Boutique({ sidebar }) {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div
      style={
        sidebar
          ? { maxHeight: "100vh", overflow: "hidden" }
          : { minHeight: "100vh", overflowX: "hidden" }
      }
    >
      <div>
        <Slideshow karim={false} slides={slides} />
      </div>
      <p
        className="text-center mt-5 pt-5 px-4 fs-5"
        style={{ fontFamily: "texte" }}
      >
        Si vous êtes près de Paris n´hésitez pas à venir nous voir. Notre
        boutique design vous attend !
      </p>
      <iframe
        title="Google Map"
        style={{ width: "100%", height: "295px" }}
        className="py-5 px-1 rounded"
        src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=13%20Quai%20du%20Ch%C3%A2telier%2C%2093450%20L%5C'%C3%8Ele-Saint-Denis+(BELMA)&amp;ie=UTF8&amp;t=p&amp;z=14&amp;iwloc=B&amp;output=embed"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
      ></iframe>
      <p className="p-0 m-0 text-center" style={{ fontFamily: "titre" }}>
        Contact
      </p>
      <a
        href="tel:+33141683338"
        style={{
          color: "#2128F5",
          display: "block",
          textDecoration: "none",
          fontFamily: "titre",
        }}
        className="text-center py-2 px-1"
      >
        +33 1 41 68 33 38
      </a>
      <p className="p-0 m-0 px-3 text-center" style={{ fontFamily: "texte" }}>
        N´hésitez pas à nous envoyer un message privé
      </p>
      <div className="row px-3 pt-3 pb-5 m-0">
        <div className="col-6">
          <a
            href="https://www.instagram.com/belma_boucherie/?hl=fr"
            target="_blank"
            rel="noopener noreferrer"
            className="text-center p-0 m-0"
          >
            Envoyer un message
          </a>
        </div>
        <div className="text-center col-6">
          <Link to="/belma_shop" className="text-center p-0 m-0">
            Voir nos produits
          </Link>
        </div>
      </div>

      {/* Partie footer */}
      {/* <div style="position: absolute;width: 80%;bottom: 10px;left: 0;right: 0;margin-left: auto;margin-right: auto;color: #000;text-align: center;"><small style="line-height: 1.8;font-size: 2px;background: #fff;">Powered by <a href="http://www.googlemapsgenerator.com/pt/">Googlemapsgenerator.com/pt/</a> & <a href="https://unoregler.com/no/">uno regler norsk</a></small></div><style>#gmap_canvas img{max-width:none!important;background:none!important}</style></div><br /> */}
      <Footer />
    </div>
  );
}
