import React, { useContext, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { UserContext } from "../../data/userContext";
import { useNavigate } from "react-router-dom";
import "../account/account.css";
import kiko from "../../Images/LOGO-BELMA_BLEU.png";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import ModalPopup from "../account/modalPopup";

export default function ShopAccount({ sidebar }) {
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(<AiOutlineEyeInvisible size={20} />);
  const [modalOpen, setModalOpen] = useState(false);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(<AiOutlineEye size={20} />);
      setType("text");
    } else {
      setIcon(<AiOutlineEyeInvisible size={20} />);
      setType("password");
    }
  };

  const { signIn } = useContext(UserContext);
  const navigate = useNavigate();
  const [validation, setValidation] = useState("");
  const inputs = useRef([]);
  const addInputs = (el) => {
    if (el && !inputs.current.includes(el)) {
      inputs.current.push(el);
    }
  };
  const navigToSingup = () => {
    navigate("/signup_belma_shop");
  };
  const handleForm = async (e) => {
    e.preventDefault();
    try {
      await signIn(inputs.current[0].value, inputs.current[1].value);
      setValidation("");
      navigate("/shop");
    } catch {
      setValidation("Le mail / mot de passe incorrect");
    }
  };

  return (
    <div
      style={
        sidebar
          ? { maxHeight: "100vh", overflow: "hidden" }
          : { minHeight: "100vh" }
      }
    >
      {modalOpen && <ModalPopup setOpenModal={setModalOpen} />}

      <div className="">
        <img
          src={kiko}
          alt=""
          className="img-fluid mx-auto d-block"
          style={{ height: "15px", marginTop: "100px" }}
        />
      </div>
      <p
        className=" m-0 p-0 mt-5 pt-0 text-center"
        style={{ fontWeight: "600", letterSpacing: "2px" }}
      >
        CONNEXION
      </p>
      <Container className="m-0 p-0">
        <Row className="m-0 p-0">
          <Col
            lg={5}
            md={6}
            sm={12}
            className="p-0 px-3 pt-5 m-auto rounded-lg"
          >
            <form onSubmit={handleForm}>
              <Form.Group role="form">
                <Form.Label style={{ fontWeight: "600" }}>
                  Votre email *{" "}
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  required
                  ref={addInputs}
                />

                <Form.Label style={{ fontWeight: "600" }} className="pt-3">
                  Votre mot de passe *
                </Form.Label>
                <div className="position-relative">
                  <input
                    type={type}
                    className="form-control"
                    placeholder="Password"
                    required
                    ref={addInputs}
                  />
                  <span
                    onClick={handleToggle}
                    className="position-absolute top-50 end-0 translate-middle-y mx-1"
                  >
                    {icon}
                  </span>
                </div>
                <p
                  className="m-0 p-1 text-end text-secondary"
                  style={{ fontSize: "0.8rem", textDecoration: "underline" }}
                  onClick={() => setModalOpen(true)}
                >
                  Mot de passe oublié ?
                </p>
                <div className="d-grid gap-2 mt-5">
                  <Button
                    variant="btn-block pt-2 text-light"
                    style={{ letterSpacing: "1.2px", background: "#2128F5" }}
                    type="submit"
                  >
                    Me connecter
                  </Button>
                </div>
                <p className="text-danger mt-1">{validation}</p>
              </Form.Group>
            </form>
          </Col>
        </Row>
        <p className="m-0 p-1 text-center mt-2" style={{ fontWeight: "500" }}>
          Vous n'avez pas de compte ?{" "}
          <span style={{ color: "#2128F5" }} onClick={navigToSingup}>
            S'inscrire
          </span>
        </p>
      </Container>
    </div>
  );
}
