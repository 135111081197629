import React from "react";
import Footer from "../../components/footer/footer";
import principal from "../../Images/LIVRAISON/1.jpeg";

export default function Delivery({ sidebar }) {
  return (
    <div
      style={
        sidebar
          ? { maxHeight: "100vh", overflow: "hidden" }
          : { minHeight: "100vh" }
      }
    >
      <img
        src={principal}
        className="img-fluid rounded px-1"
        alt="Responsive image"
        style={{ marginTop: "100px" }}
      />{" "}
      <h3
        className="text-center pt-4 px-1 pb-3 m-0"
        style={{ fontFamily: "titre" }}
      >
        Conditions de livraisons
      </h3>
      <p className="m-0 text-center pt-3">
        Pour une livraison dans les règles de l´art nous avons choisi le service
        Chronofresh. Chronopost prend soin de votre colis en respectant la
        chaine du froid, conservant ainsi toute leur fraicheur.
      </p>
      <h6
        style={{
          fontWeight: "600",
          padding: "25px 1px 25px 3%",
          fontFamily: "titre",
        }}
        className="text-center"
      >
        Livraison en France Metropolitaine
      </h6>
      <p className="m-0 pt-0 px-2 text-center">
      Chers clients, <br />
      Veuillez prendre en compte les informations ci-dessous pour vos prochaines commandes :
      </p>
      <div className="px-1 mt-3">
        <table className="table m-0">
          <thead>
            <tr>
              <th className="text-center">Jour de votre commande</th>
              <th className="text-center">Jour de votre livraison</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td data-label="S.No" className="text-center m-0 p-auto px-0">
                LUNDI
              </td>
              <td data-label="Name" className="text-center">
              MERCREDI OU JEUDI
              </td>
            </tr>
            <tr>
              <td data-label="S.No" className="text-center">
                MARDI
              </td>
              <td data-label="Name" className="text-center">
              JEUDI OU VENDREDI
              </td>
            </tr>

            <tr>
              <td data-label="S.No" className="text-center">
              MERCREDI
              </td>
              <td data-label="Name" className="text-center">
              VENDREDI OU SAMEDI
              </td>
            </tr>

            <tr>
              <td data-label="S.No" className="text-center">
              JEUDI AVANT 12H
              </td>
              <td data-label="Name" className="text-center">
              VENDREDI OU SAMEDI
              </td>
            </tr>

            <tr>
              <td data-label="S.No" className="text-center">
              JEUDI APRES 12H
              </td>
              <td data-label="Name" className="text-center">
              SAMEDI OU LUNDI
              </td>
            </tr>

            <tr>
              <td data-label="S.No" className="text-center">
              VENDREDI
              </td>
              <td data-label="Name" className="text-center">
              MERCREDI OU JEUDI
              </td>
            </tr>

            <tr>
              <td data-label="S.No" className="text-center">
              SAMEDI
              </td>
              <td data-label="Name" className="text-center">
              MERCREDI OU JEUDI
              </td>
            </tr>

            <tr>
              <td data-label="S.No" className="text-center">
              DIMANCHE
              </td>
              <td data-label="Name" className="text-center">
              MERCREDI OU JEUDI
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p
        style={{
          fontWeight: "700",
          padding: "0px 1px 0px 3%",
          fontSize: "0.6rem",
        }}
      >
        Tous les colis sont livrés entre 8h30 et 13h00.
      </p>
      <p className="m-0 pt-3 px-2 text-center">
        Choissisez le lieu de votre choix et Chronofresh assurera votre
        livraison.
      </p>
      <p
        className="m-0 pt-3 px-2 text-center"
        style={{ fontWeight: "600", fontFamily: "titre" }}
      >
        Les frais de livraison
      </p>
      <div className="px-1 mt-3">
        <table className="table m-0">
          <thead>
            <tr>
              <th className="text-center">Montant de la commande</th>
              <th className="text-center">
                Frais de préparation et d’expédition
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td data-label="S.No" className="text-center m-0 p-auto px-0">
                de 0 à 50€
              </td>
              <td data-label="Name" className="text-center">
                25€
              </td>
            </tr>

            <tr>
              <td data-label="S.No" className="text-center">
                &gt; 50€ à 140€
              </td>
              <td data-label="Name" className="text-center">
                20€
              </td>
            </tr>
            <tr>
              <td data-label="S.No" className="text-center p-auto px-0">
                &gt; 140€
              </td>
              <td data-label="Name" className="text-center">
                LIVRAISON OFFERTE
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p
        className="m-0 pt-5 mb-2 text-center"
        style={{ fontWeight: "600", fontFamily: "titre" }}
      >
        Conseil lors de la réception de votre colis
      </p>
      <p className="m-0 pt-3 px-2 text-center">
        La viande fraiche étant sensible et périssable, il est important de vous
        assurer de votre présence le jour de la livraison. Lors de l’expédition
        de votre commande, vous recevrez un email avec un lien pour suivre
        l’avancée de votre colis. Dans le cas où votre colis ne serait pas
        réceptionné lors du premier passage, Chronofresh vous contactera ou vous
        laissera un avis de passage. Vous pouvez également contacter Chronofresh
        via le lien envoyé lors de la commande ou par téléphone au 0 969 320 311
        pour choisir une nouvelle date de livraison.
      </p>
      <p className="m-0 pt-3 px-2 text-center">
        Vous pouvez programmer le jour de livraison grâce au système Predict™
        proposé par Chronofresh et cela jusqu’à la veille de la livraison
        prévue. Il est important de noter que si le colis n’est toujours pas
        réceptionné lors de la deuxième présentation du livreur, celui- ci sera
        détruit et restera facturé.
      </p>
      <p
        className="m-0 pt-5 mb-2 text-center"
        style={{ fontWeight: "600", fontFamily: "titre" }}
      >
        Préparation de votre colis
      </p>
      <p className="m-0 pt-3 px-2 text-center">
        Vos produits sont emballés dans une caisse isotherme ISOPRO® qui
        conserve les aliments en froid positif de 0 à 4°C. Nous ajoutons aussi
        des packs réfrigérants pour conserver la chaîne de froid. Ce procédé
        vous assure la conservation optimale de la viande pendant 48h.
      </p>
      <p
        className="m-0 pt-5 mb-2 text-center"
        style={{ fontWeight: "600", fontFamily: "titre" }}
      >
        Conseil lors de la réception de votre colis
      </p>
      <p className="m-0 pt-3 px-2 text-center pb-5">
        La viande étant un produit sensible et périssable il est important de
        vous assurer de votre présence le jour de la livraison. Le carton
        réfrigéré vous assure une température optimale durant 48h maximum.
        Veuillez prendre vos dispositions le jour de la réception du colis. Pour
        toutes questions n´hésitez pas à nous contacter.
      </p>
      <Footer />
    </div>
  );
}
