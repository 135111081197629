import React from "react";
import { Link } from "react-router-dom";
import "./floatingProfil.css"; // Updated file name

import profileIcon from "../../Images/profil_icone.svg"; // Updated image name

export default function FloatingProfile() {
  return (
    <Link to="/belma_account">
      <div className="floating-profile-container">
        {" "}
        <div className="profile-icon-container">
          {" "}
          <img
            src={profileIcon}
            alt="Profile Icon"
            className="profile-icon"
          />{" "}
        </div>
      </div>
    </Link>
  );
}
