import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from "../firebase-config";

const productsCollectionRef = collection(db, "products");

class ProductsDataService {
  getAllProducts = () => {
    const q = query(productsCollectionRef, orderBy("ordre", "asc"));
    return getDocs(q);
  };
}
export default new ProductsDataService();
