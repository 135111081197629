import React, { useContext, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { UserContext } from "../../../data/userContext";
import { useNavigate } from "react-router-dom";
import "./signUpPage.css"; // Import CSS file for styling (assuming it exists)
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import UsersDataService from "../../../data/users.services";
import { auth } from "../../../firebase-config";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();
const envoiMailClientBienvenue = httpsCallable(functions, 'envoiMailClientBienvenue');

export default function SignUpPage({ sidebar }) {
  const [particulier, setParticulier] = useState('particulier');
  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(<AiOutlineEyeInvisible size={20} />);
  const [validation, setValidation] = useState("");

  const handleToggle = () => {
    setType(prevType => prevType === 'password' ? 'text' : 'password');
    setIcon(prevIcon => prevIcon === <AiOutlineEyeInvisible size={20} /> ? <AiOutlineEye size={20} /> : <AiOutlineEyeInvisible size={20} />);
  }

  const { signup } = useContext(UserContext);
  const navigate = useNavigate();
  const inputs = useRef([]);

  const addInputs = (el) => {
    if (el && !inputs.current.includes(el)) {
      inputs.current.push(el);
    }
  };

  const navigToSignup = () => {
    navigate("/belma_account");
  }

  const handleForm = async (e) => {
    e.preventDefault();
    const email = inputs.current[0].value;
    const password = inputs.current[1].value;

    // Client-side form validation (example)
    if (!email || !password) {
      setValidation("Veuillez remplir tous les champs.");
      return;
    }

    try {
      await signup(email, password);

      const newUser = {
        firstName: '',
        lastName: '',
        tel: '',
        address: {
          num: "",
          zip: "",
          country: "",
          adrressName: "",
          city: ""
        },
        date_inscription: new Date(),
        type: particulier,
        email: auth.currentUser.email
      }

      await UsersDataService.addUser(auth.currentUser.uid, newUser);

      setValidation("");
      navigate("/belma_account");
      envoiMailClientBienvenue({
        email: auth.currentUser.email,
      });
    } catch {
      setValidation("Un compte existe déjà avec cette adresse email, veuillez vous connecter");
    }
  };

  const changeHandlerPart = (changeEvent) => {
    setParticulier(changeEvent.target.value);
  }

  return (
    <>
      <div style={sidebar ? { maxHeight: '100vh', overflow: "hidden" } : { overflow: 'hidden' }}>
        <div style={{ height: "5em" }}></div> {/* empty div for spacing */}
        <p className='m-0 p-0 mt-5 pt-0 text-center' style={{ fontWeight: '600', letterSpacing: '2px'}}>INSCRIPTION</p>
        <Container className="m-0 p-0 mx-auto">
          <Row className="m-0 p-0">
            <Col lg={5} md={6} sm={12} className="p-0 px-3 pt-5 m-auto rounded-lg">
              <form onSubmit={handleForm}>
                <Form.Group role="form">
                  <div className="d-flex justify-content-around mb-3">
                    <div className="form-check mb-3">
                      <input className="form-check-input choixXX" type="radio" value='particulier' checked={particulier === 'particulier'} onChange={changeHandlerPart} />
                      <label className="form-check-label">Particulier</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input choixXX" type="radio" value='professionnel' checked={particulier === 'professionnel'} onChange={changeHandlerPart} />
                      <label className="form-check-label">Professionnel</label>
                    </div>
                  </div>

                  <Form.Label style={{ fontWeight: '600' }}>Votre email *</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" required ref={addInputs} />

                  <Form.Label style={{ fontWeight: '600' }} className='pt-3'>Votre mot de passe *</Form.Label>
                  <div className='position-relative'>
                    <input type={type} className="form-control" placeholder="Password" required ref={addInputs} />
                    <span onClick={handleToggle} className='position-absolute top-50 end-0 translate-middle-y mx-1'>{icon}</span>
                  </div>

                  <div className="d-grid gap-2 mt-5">
                    <Button variant="btn-block pt-2 text-light" style={{ letterSpacing: '1.2px', background: '#2128F5' }} type="submit">
                      M'inscrire
                    </Button>
                  </div>
                  <p className="text-danger mt-1">{validation}</p>
                </Form.Group>
              </form>
            </Col>
          </Row>
          <p className="m-0 p-1 text-center mt-2" style={{ fontWeight: '500' }}>Vous avez déjà un compte ? <span style={{ color: '#2128F5', cursor: 'pointer' }} onClick={navigToSignup}>Se connecter</span></p>
        </Container>
      </div>
    </>
  )
}
