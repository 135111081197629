import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/footer";
import AOS from "aos";
import "aos/dist/aos.css";
import SlideShow from "../../components/slide/slideshow";
import image1 from "../../Images/KARIM/1.jpg";
import image2 from "../../Images/KARIM/2.jpg";
import image3 from "../../Images/KARIM/3.jpg";
import image4 from "../../Images/KARIM/4.jpg";
import image5 from "../../Images/KARIM/5.jpg";

export default function Start({ sidebar }) {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const slides = [
    { src: image1, alt: "Slide 1" },
    { src: image2, alt: "Slide 2" },
    { src: image3, alt: "Slide 3" },
    { src: image4, alt: "Slide 3" },
    { src: image5, alt: "Slide 3" },
  ];
  return (
    <div
      style={
        sidebar
          ? { maxHeight: "100vh", overflow: "hidden" }
          : { minHeight: "100vh", overflowX: "hidden" }
      }
    >
      <div style={{ margin: "100px 5px 0px 5px" }}>
        <SlideShow karim={true} slides={slides} />
      </div>
      <p
        className="text-center mt-5 pt-3 px-1 fs-5"
        style={{ fontFamily: "texte" }}
      >
        Notre star Karim vous attend dans notre boutique design. Plus de 20 ans
        qu´il s’est initié dans l’art de la découpe. Grand connaisseur des
        meilleurs éleveurs et races. Gourmet toujours en création de belles
        recettes. N´hésitez pas à demander conseil à notre Butcher Gourmet !
      </p>
      <div className="text-center mb-5 pb-5">
        <Link to="/belma_shop" style={{ fontFamily: "texte" }}>
          Découvrir
        </Link>
      </div>
      <Footer />
    </div>
  );
}
